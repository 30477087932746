import React, {Component} from "react";
import {Accordion, Badge, Image} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class Admission extends Component {


    render() {
        return (
            <div className="container-fluid pt-5 border-top-gray mb-5">
                <div className="container">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <h1>Порядок поступления</h1>
                            <p>
                                Санкт-Петербургские организации, реализующие программы среднего
                                профессионального образования, предлагают несколько вариантов обучения.
                            </p>
                            <p>
                                Это может быть очная форма – все занятия проходят днем, при личном присутствии студентов
                                в
                                университете.
                            </p>
                            <p>
                                Есть очно-заочное обучение – предполагает занятия вечером в будни и
                                днем в выходные, а также заочное обучение – самостоятельное обучение и экзамены
                                несколько раз в год.
                            </p>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Image
                            variant="top"
                            src={process.env.PUBLIC_URL + '/resources/images/admission.png'} />
                        </Col>
                    </Row>


                    <Accordion className="mt-5">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>1. Выберите организацию, профессию, программу</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Система среднего профессионального образования Санкт-Петербурга включает 104
                                    образовательные организации, из них – 70 государственных профессиональных
                                    образовательных организаций; 34 структурных подразделения в образовательных
                                    организациях высшего образования, осуществляющие подготовку специалистов среднего
                                    звена.
                                </p>
                                <p>
                                    В Санкт-Петербурге можно получить практически любую востребованную на рынке труда
                                    профессию. Абитуриенту нужно лишь выбрать оптимальную для него программу.
                                    Определитесь с критериями (направление обучения, форма и язык обучения) и начните
                                    поиск.
                                </p>
                                <p>
                                    По любым вопросам, связанным с Вашим обучением в Санкт-Петербурге, обращайтесь
                                    напрямую в организации, либо свяжитесь с нами - для этого перейдите в раздел
                                    контакты.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>2. Узнайте о финансировании</Accordion.Header>
                            <Accordion.Body className="p-4">
                                <Row>
                                    <Col xs={12} className="mb-4">
                                        Граждане Российской Федерации и иностранные граждане
                                        могут учиться по программам среднего профессионального образования в
                                        образовательных учреждениях Санкт-Петербурга за счет бюджета правительства
                                        Российской Федерации или на коммерческой основе.
                                        Обратите внимание на условия приема при подаче заявления через портал
                                        «Электронный
                                        колледж» или уточните условия в приемной комиссии организации.
                                    </Col>
                                    <Col xs={12}>
                                        <h3 className="slab fw-bold mt-4 mb-4">Обучение за счет средств бюджета
                                            Российской
                                            Федерации</h3>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <p className="fw-bold">
                                            Вариант обучения 1:
                                        </p>
                                        <p className="fw-bold" style={{color: "#0094ff"}}>
                                            Обучение на равных правах с гражданами РФ (на общих основаниях,
                                            конкурсной основе)
                                        </p>
                                        <p>
                                            Абитуриенты: некоторые категории иностранцев, соотечественники, проживающие
                                            за рубежом, переселенцы.
                                        </p>
                                        <p>
                                            Где узнавать информацию и куда подавать документы: подавать заявление и
                                            документы
                                            через портал «Электронный колледж», а также в приемные комиссии
                                            образовательных
                                            организаций.
                                        </p>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <p className="fw-bold">Вариант обучения 2:
                                        </p>
                                        <p className="fw-bold" style={{color: "#0094ff"}}>
                                            Госстипендия (квота правительства Российской Федерации)
                                        </p>
                                        <p>
                                            Абитуриенты: из стран, которым выделена квота в текущем учебному году, в том
                                            числе соотечественники, проживающие за рубежом.
                                        </p>
                                        <p>
                                            Где узнавать информацию и куда подавать документы: представительства
                                            Россотрудничества, российские посольства в вашей стране
                                        </p>
                                    </Col>
                                    <Col xs={12} lg={12} className="mt-5 mb-4">
                                        <h3 className="slab fw-bold">Обучение на коммерческой основе</h3>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <p className="fw-bold">
                                            Вариант обучения 1:
                                        </p>
                                        <p className="fw-bold" style={{color: "#0094ff"}}>
                                            Обучение по контракту за счет собственных средств
                                        </p>
                                        <p>
                                            Абитуриенты: из всех стран
                                        </p>
                                        <p>
                                            Где узнавать информацию и куда подавать документы: при наличии собственных
                                            средств на обучение абитуриенты могут выбрать понравившееся учебное
                                            заведение,
                                            специальность, открытый набор и подать заявление и документы через портал
                                            «Электронный колледж», а также в приемные комиссии образовательных
                                            организаций.
                                        </p>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <p className="fw-bold">
                                            Вариант обучения 2:
                                        </p>
                                        <p className="fw-bold" style={{color: "#0094ff"}}>
                                            Обучение по контракту за счет спонсоров, целевое обучение
                                        </p>
                                        <p>
                                            Абитуриенты: из всех стран
                                        </p>
                                        <p>
                                            Где узнавать информацию и куда подавать документы: подавать заявление и
                                            документы через портал «Электронный колледж», а также в приемные комиссии
                                            образовательных
                                            организаций.
                                        </p>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>3. Соберите необходимые документы</Accordion.Header>
                            <Accordion.Body>
                                <h3 className="slab fw-bold mt-3 mb-4">Основные документы для граждан Российской
                                    Федерации</h3>
                                <ul>
                                    <li>
                                        Оригинал или ксерокопию документов, удостоверяющих личность и гражданство
                                        (паспорт);
                                    </li>
                                    <li>
                                        Оригинал или ксерокопию документа об образовании и (или) документа об
                                        образовании и о квалификации (аттестат об окончании 9-го или 11-го класса и
                                        (или) диплом о среднем или высшем профессиональном образовании);
                                    </li>
                                    <li>
                                        4 фотографии;
                                    </li>
                                    <li>
                                        Заявление о приеме на обучение;
                                    </li>
                                    <li>
                                        Согласие на обработку персональных данных
                                    </li>

                                </ul>
                                <h3 className="slab fw-bold mt-5 mb-4">Дополнительные документы</h3>
                                <ul>
                                    <li>
                                        Личные документы
                                    </li>
                                    <li>
                                        Иные образовательные документы
                                    </li>
                                    <li>
                                        Медицинские документы
                                    </li>
                                    <li>
                                        Индивидуальные достижения
                                    </li>
                                </ul>
                                <p>
                                    Информацию о перечне дополнительных документов, необходимых для поступления в
                                    выбранную образовательную организацию, можно узнать при подаче заявления через
                                    портал «Электронный колледж», а также на официальном сайте образовательной
                                    организации или связавшись с приемной комиссией.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>4. Подача заявления</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Подайте заявление и документы в установленные образовательной организацией сроки.
                                    Приказ Минобрнауки России от 23.01.2014 №36 устанавливает следующие обязательные
                                    даты, касающиеся приема абитуриентов в колледжи и техникумы:
                                </p>
                                <Row className="mt-5 mb-2">
                                    <Col xs={4} sm={3}>
                                        <h5 className="text-end"><Badge style={{minWidth: 140}} bg="primary">До 1
                                            марта</Badge></h5>
                                    </Col>
                                    <Col xs={8} sm={9}>
                                        <p>
                                            Публикация информации о приёмной кампании (правила приёма,
                                            условия обучения, список специальностей, вступительные испытания и т.д.);
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                    <Col xs={4} sm={3}>
                                        <h5 className="text-end"><Badge style={{minWidth: 140}} bg="primary">До 1
                                            июня</Badge></h5>
                                    </Col>
                                    <Col xs={8} sm={9}>
                                        <p>
                                            Публикация информации о количестве мест, правил подачи и
                                            рассмотрения апелляций по результатам вступительных испытаний, количестве
                                            мест в общежитиях;
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                    <Col xs={4} sm={3}>
                                        <h5 className="text-end"><Badge style={{minWidth: 140}} bg="primary">До 20
                                            июня</Badge></h5>
                                    </Col>
                                    <Col xs={8} sm={9}>
                                        <p>Начало приёма документов;</p>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-2">
                                    <Col xs={4} sm={3}>
                                        <h5 className="text-end"><Badge style={{minWidth: 140}} bg="primary">До 10
                                            августа</Badge></h5>
                                    </Col>
                                    <Col xs={8} sm={9}>
                                        <p>
                                            Завершение приёма документов у лиц, сдающих вступительные
                                            испытания;
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mt-2 mb-5">
                                    <Col xs={4} sm={3}>
                                        <h5 className="text-end"><Badge style={{minWidth: 140}} bg="primary">До 15
                                            августа</Badge></h5>
                                    </Col>
                                    <Col xs={8} sm={9}>
                                        <p>Завершение приема документов на очную форму обучения</p>
                                    </Col>
                                </Row>
                                <p className="mt-3">
                                    Сроки приема заявлений в образовательные организации на очно-заочную и заочную
                                    формы обучения устанавливаются внутренними правилами приема каждого учреждения.
                                    Точные сроки начала и завершения приема документов необходимо уточнять на портале
                                    «Электронный колледж» или непосредственно в приемной комиссии образовательной
                                    организации.
                                </p>
                                <p>
                                    Приказ Минобрнауки России от 23.01.2014 №36 устанавливает следующие возможности
                                    подачи документов:
                                </p>
                                <ul>
                                    <li>Лично</li>
                                    <li>Через операторов почтовой связи (почта)</li>
                                    <li>В электронной форме</li>
                                    <li> Доступные возможности подачи документов необходимо уточнять
                                        непосредственно в приемной комиссии образовательной организации.
                                    </li>
                                </ul>
                                <h3 className="slab fw-bold mt-5">
                                    Иностранным гражданам
                                </h3>
                                <p className="fw-bold">Документы для подачи первичной заявки:</p>
                                <p style={{color: "#0094ff"}}>
                                    Для обучения по квоте
                                </p>
                                <ul>
                                    <li>
                                        Копия документа об образовании, с перечислением предметов и оценок (с
                                        переводом на русский язык)
                                    </li>
                                    <li>
                                        Копия удостоверения личности
                                    </li>
                                    <li>
                                        Заполненная анкета (бланк выдает университет или представительство
                                        Россотрудничества)
                                    </li>
                                    <li>
                                        Фотографии (по требованию)
                                    </li>
                                </ul>
                                <p>
                                    Все документы должны быть переведены на русский язык и заверены в установленном
                                    порядке (нотариально или в консульском департаменте посольства). Полный список
                                    документов и порядок заверения уточняйте в образовательных организациях.
                                </p>
                                <p className="fw-bold">Документы для подачи в образовательную организацию</p>
                                <p style={{color: "#0094ff"}}>
                                    Для всех видов финансирования
                                </p>
                                <p className="fw-bold">
                                    Основные документы
                                </p>
                                <ul>
                                    <li>
                                        Копия документа, удостоверяющего личность поступающего, либо документ,
                                        удостоверяющий личность иностранного гражданина в Российской Федерации, в
                                        соответствии со статьей 10 Федерального закона от 25 июля 2002 г. № 115-ФЗ «О
                                        правовом положении иностранных граждан в Российской Федерации»;
                                    </li>
                                    <li>
                                        Оригинал документа иностранного государства об образовании и (или) о
                                        квалификации (или его заверенную в установленном порядке копию), если
                                        удостоверяемое указанным документом образование признается в Российской
                                        Федерации на уровне соответствующего образования в соответствии со статьей
                                        107 Федерального закона от 29 декабря 2012 г. № 273-ФЗ «Об образовании в
                                        Российской Федерации»(в случае, установленном Федеральным законом от 29
                                        декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации», - также
                                        свидетельство о признании иностранного образования);
                                    </li>
                                    <li>
                                        Заверенный в установленном порядке перевод на русский язык документа
                                        иностранного государства об образовании и (или) о квалификации и приложения к
                                        нему (если последнее предусмотрено законодательством государства, в котором
                                        выдан такой документ);
                                    </li>
                                    <li>
                                        копии документов или иных доказательств, подтверждающих принадлежность
                                        соотечественника, проживающего за рубежом, к группам, предусмотренным
                                        статьей 17 Федерального закона от 24 мая 1999 г. № 99-ФЗ «О государственной
                                        политике Российской Федерации в отношении соотечественников за рубежом»;
                                    </li>
                                    <li>
                                        4 фотографии
                                    </li>
                                    <li>
                                        Заявление о приеме
                                    </li>
                                    <li>
                                        Согласие на обработку персональных данных
                                    </li>
                                </ul>
                                <p className="fw-bold">Дополнительные документы</p>
                                <ul>
                                    <li>Миграционные документы</li>
                                    <li>Медицинские документы</li>
                                    <li>Индивидуальные достижения</li>
                                </ul>
                                <p>
                                    Подробнее о том, какие документы нужны на каждом из этапов, расскажут сотрудники:
                                </p>
                                <ul>
                                    <li>приемных комиссий образовательных организаций</li>
                                    <li>представительств Россотрудничества или посольства России в вашей стране (если
                                        участвуете в конкурсном отборе на обучение по госстипендии)
                                    </li>
                                </ul>
                                <p>
                                    В большинстве случаев документы нуждаются в легализации на родине. Абитуриентам из
                                    некоторых стран также придется пройти процедуру признания их дипломов или
                                    аттестатов в России.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>5. Пройдите конкурсный отбор</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Прием абитуриентов в организации, реализующие программы среднего
                                    профессионального образования, на большинство специальностей производится на
                                    конкурсной основе только по результатам оценки среднего балла аттестата. Средний
                                    балл
                                    аттестата считается как среднее арифметическое оценок по учебным предметам,
                                    указанным в приложении к аттестату.
                                </p>
                                <p>
                                    Однако при приёме на обучение по тем образовательным программам среднего
                                    профессионального образования, где освоение специальности требует наличия у
                                    поступающего определенных творческих способностей, физических и (или)
                                    психологических качеств, проводятся вступительные испытания:
                                </p>
                                <ul>
                                    <li>Физическое испытание</li>
                                    <li>Творческое испытание</li>
                                    <li>Психологическое испытание</li>
                                </ul>
                                <p>
                                    Перечень специальностей, требующих прохождения вступительных испытаний, можно
                                    уточнить на портале «Электронный колледж» или в приемной комиссии.
                                </p>
                                <h3 className="slab mt-4 fw-bold">Иностранным гражданам</h3>
                                <p>
                                    При поступлении по государственной квоте абитуриент проходит отборочные испытания в
                                    своей стране, а затем уже проходит вступительные испытания (при наличии) в
                                    образовательных организациях РФ.
                                </p>
                                <p>
                                    Узнайте о зачислении по итогам вступительных испытаний на сайте образовательной
                                    организации и получите приглашение в РФ.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>6. Получите приглашение</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Зарегистрированные на портале «Электронный колледж» абитуриенты по итогам
                                    прохождения конкурсного отбора автоматически получат приглашение на зачисление и
                                    смогут его акцептовать или отклонить на портале. После отклонения приглашения ваше
                                    место будет предоставлено следующему по конкурсу абитуриенту, и вы будете
                                    исключены из списка абитуриентов этого образовательного учреждения по данному
                                    набору. Восстановление в списке после отклонения приглашения на зачисление
                                    невозможно.
                                </p>
                                <h3 className="slab fw-bold mt-4">Иностранным гражданам</h3>
                                <p>
                                    Абитуриентам из стран, имеющих безвизовый режим с Россией, для въезда на ее
                                    территорию достаточно предъявить загранпаспорт (список стран, имеющих безвизовый
                                    режим с Россией, приведен на сайте МИД России). В остальных случаях для въезда на
                                    территорию России помимо документа, удостоверяющего личность, нужно предъявить
                                    учебную визу.
                                </p>
                                <p>
                                    Также для оформления учебной визы вам понадобится приглашение:
                                </p>
                                <ul>
                                    <li>Приглашение для иностранцев оформляют Министерство иностранных дел России
                                        (МИД России) и Главное управление по вопросам миграции МВД РФ.
                                    </li>
                                    <li>Абитуриенты, направленные в образовательные организации по итогам
                                        конкурсного отбора на госстипендии (квота), получают визы по указанию МИД. В
                                        представительстве Россотрудничества им сообщают шестизначный номер
                                        приглашения, который они называют при сдаче документов на визу.
                                    </li>
                                </ul>
                                <p>
                                    Если абитуриент поступал в образовательную организацию на общих основаниях или на
                                    коммерческой основе, то приглашение ему пришлет образовательная организация. При
                                    оформлении визы понадобится оригинал этого документа.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        )
    }
};
