import api from './api';


class DocumentService {
    getDocumentTypes() {
        let data = {
            "filter": {
                "conditions": [
                    {
                        "property": "ownerType",
                        "operator": "=",
                        "value": "DOT_PERSON"
                    }
                ]
            }
        };
        return api.post('/rest/entities/DocumentType/search', data);
    }
    getDocumentKind(type) {
        let data = {
            "filter": {
                "conditions": [
                    {
                        "property": "type",
                        "operator": "=",
                        "value": type
                    }
                ]
            }
        };
        return api.post('/rest/entities/PersonDocumentKind/search', data);
    }
    uploadFile(formData) {
        return api.post('/rest/files', formData)
    }
    saveNewDocument (document) {
        return api.post('/rest/entities/PersonDocument?responseFetchPlan=full', document)
    }
    updateDocument (document) {
        return api.put('/rest/entities/PersonDocument/' + document.id + '?responseFetchPlan=full', document)
    }
    deleteDocument (id) {
        return api.delete('/rest/entities/PersonDocument/' + id);
    }
}
export default new DocumentService();
