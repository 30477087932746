import React, {Component} from "react";
import {Redirect} from 'react-router-dom';
import {Tab, Tabs, Row, Col} from "react-bootstrap";
import {connect} from "react-redux";
import UserService from '../services/user.service'

class Profile extends Component {
    componentDidMount() {
        UserService.getUserInfo()
    }

    render() {
        const access = this.props.access;
        const user = this.props.user;

        if (!access.isLoggedIn) {
            return <Redirect to="/login"/>;
        }

        return (
            <div className="container-fluid">
                <div className="main-content">
                    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                        <Row>
                            <Col sm={12}>
                                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab eventKey="home" title="Анкета">

                                    </Tab>
                                    <Tab eventKey="profile" title="Документы">

                                    </Tab>
                                    <Tab eventKey="contact" title="Аттестат">

                                    </Tab>
                                    <Tab eventKey="user" title="Учетная запись">

                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col sm={8}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="#link1">

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#link2">

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                    {/*<header className="jumbotron">
                        <h3>
                            <strong>{user.data.firstName + " " + user.data.lastName}</strong> Profile
                        </h3>
                    </header>
                    <p>
                        <strong>Token:</strong> {access.auth.accessToken.substring(0, 20)} ...{" "}
                        {access.auth.accessToken.substr(access.auth.accessToken.length - 20)}
                    </p>
                    <p>
                        <strong>Id:</strong> {user.data.id}
                    </p>
                    <p>
                        <strong>Email:</strong> {user.data.email}
                    </p>
                    <strong>Authorities:</strong>*/}


                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const access = state.auth;
    const user = state.user;
    return {
        access,
        user
    };
}

export default connect(mapStateToProps)(Profile);
