import {
    SET_MESSAGE,
    GET_SPECIALTY_SUCCESS, GET_SPECIALTY_FAIL, GET_INTAKES_SUCCESS, GET_INTAKES_FAIL
} from "./types";

import IntakeService from "../services/intake.service";


export const getSpecialities = () => (dispatch) => {
    return IntakeService.getSpecialties().then(
        (data) => {
            dispatch({
                type: GET_SPECIALTY_SUCCESS,
                payload: data.data
            });
            return Promise.resolve();
        }).catch((error) => {
        dispatch({
            type: GET_SPECIALTY_FAIL
        });
        dispatch({
            type: SET_MESSAGE,
            payload: "Ошибка при загрузке списка колледжей и специальностей",
        });
        return Promise.reject();
    })
};

export const getIntakes = () => (dispatch) => {
    return IntakeService.getIntakes().then(
        (data) => {
            dispatch({
                type: GET_INTAKES_SUCCESS,
                payload: data.data
            });
            return Promise.resolve();
        }).catch((error) => {
        dispatch({
            type: GET_INTAKES_FAIL
        });
        dispatch({
            type: SET_MESSAGE,
            payload: "Ошибка при загрузке наборов",
        });
        return Promise.reject();
    })
};

export const getIntake = (id) => (dispatch) => {
    return IntakeService.getIntake(id).then(
        (data) => {
            return Promise.resolve(data);
        }).catch((error) => {
        return Promise.reject(error);
    })
};
export const getIntakeDocuments = (id) => (dispatch) => {
    return IntakeService.getIntakeDocuments(id)
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};
export const getIntakeSubjects = (id) => (dispatch) => {
    return IntakeService.getIntakeSubjects(id)
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};
export const getCollegeDocuments = (id) => (dispatch) => {
    return IntakeService.getCollegeDocuments(id)
        .then((data) => {
            if (data.data) {
                return Promise.resolve(data.data);
            } else {
                return Promise.reject(false);
            }

        })
        .catch((error) => {
            return Promise.reject(error);
        })
};
