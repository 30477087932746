import React, {Component} from "react";
import {getApplicants} from "../actions/user";
import {connect} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faCalendar,
    faCheck,
    faFile,
    faFileContract,
    faPersonChalkboard
} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";
import UserService from "../services/user.service";

const applicantStatuses = [
    {
        value: "AS_CANDIDATE",
        label: "Новое",
        color: "#555"
    },
    {
        value: "AS_PROCESSING",
        label: "В работе",
        color: "#555"
    },
    {
        value: "AS_DISMISSED",
        label: "Отклонено",
        color: "#dc3545"
    },
    {
        value: "AS_ENTRANT",
        label: "Участвует в конкурсе",
        color: "#0094ff"
    },
    {
        value: "AS_REFUSED",
        label: "Отозвано",
        color: "#dc3545"
    },
    {
        value: "AS_RECOMMENDED",
        label: "Рекомендован к зачислению",
        color: "#0094ff"
    },
    {
        value: "AS_ENROLLED",
        label: "Зачислен",
        color: "#0094ff"
    },
];

class Applications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applicants: [],
            filters: false,
            status: "",
            budget: "",
            type: "",
            loading: true,
        };
    }

    componentDidMount() {
        this.getApplicants()
    }

    getApplicants = (person, id) => {
        this.setState({
            loading: true
        });
        const {dispatch} = this.props;
        return dispatch(getApplicants(person, id))
            .then(() => {
                this.setState({
                    applicants: this.props.user.applicants,
                    loading: false
                })
            })
            .catch(() => {
                this.setState({
                    applicants: this.props.user.applicants,
                    loading: false
                })
            })
    };
    revoke = async (id) => {
        await UserService.revokeApplicant(id);
        this.getApplicants()
    };
    changeFilters = () => {
        setTimeout(() => {
            if (!this.state.status.length && !this.state.budget.length && !this.state.type.length) {
                this.setState({
                    filters: false,
                    applicants: this.props.user.applicants,
                })
            } else {
                let applicants = Object.assign([], this.props.user.applicants);
                if (this.state.status.length) {
                    console.log(this.state.status);
                    applicants = applicants.filter(a => a.status === this.state.status);
                }
                if (this.state.budget.length) {
                    console.log(this.state.status);
                    applicants = applicants.filter(a => a.intake.budgetType === this.state.budget);
                }
                if (this.state.type.length) {
                    applicants = applicants.filter(a => a.intake.specialty.studyForm === this.state.type);
                }
                this.setState({
                    filters: true,
                    applicants: applicants,
                })
            }
        }, 20)
    };
    clearFilters = () => {
        this.setState({
            status: "",
            budget: "",
            type: "",
        });
        this.changeFilters()
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="main-content">
                    <div className="top-filters">
                        <Row>
                            <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Статус</Form.Label>
                                    <select className={"form-control"}
                                            value={this.state.status}
                                            onChange={(e) => {
                                                this.setState({
                                                    status: e.target.value
                                                });
                                                this.changeFilters();
                                            }}>
                                        <option value="">Все статусы</option>
                                        {applicantStatuses.map(s => {
                                            return (
                                                <option key={s.value} value={s.value}>{s.label}</option>
                                            )
                                        })}
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Тип бюджета</Form.Label>
                                    <select className={"form-control"}
                                            value={this.state.budget}
                                            onChange={(e) => {
                                                this.setState({
                                                    budget: e.target.value
                                                });
                                                this.changeFilters();
                                            }}>
                                        <option value="">Все типы</option>
                                        <option value="BT_BUDGET">Бюджет</option>
                                        <option value="BT_COMMERCIAL">Платный</option>
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Форма обучения</Form.Label>
                                    <select className={"form-control"}
                                            value={this.state.type}
                                            onChange={(e) => {
                                                this.setState({
                                                    type: e.target.value
                                                });
                                                this.changeFilters();
                                            }}>
                                        <option value="">Все формы</option>
                                        <option value="SF_FULLTIME">Очная</option>
                                        <option value="SF_CORRESPONDENCE">Заочная</option>
                                        <option value="SF_EVENING">Очно-заочная</option>
                                    </select>
                                </Form.Group>
                            </Col>
                            {this.state.filters &&
                            <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={2}>
                                <Button style={{marginTop: 25}} variant="outline-danger"
                                        onClick={() => this.clearFilters()}>Сбросить</Button>
                            </Col>
                            }
                        </Row>
                    </div>
                    {!this.state.loading ?
                        this.state.applicants.length ?
                        this.state.applicants.sort(function(a,b) {return new Date(b.createdDate) - new Date(a.createdDate)}).map((a, index) => {
                            const status = applicantStatuses.find(s => s.value === a.status)? applicantStatuses.find(s => s.value === a.status).label : "Неизвестный статус";
                            const color = applicantStatuses.find(s => s.value === a.status)? applicantStatuses.find(s => s.value === a.status).color : "#000";
                            return (
                                <div key={index} className="application d-flex flex-wrap flex-lg-nowrap">
                                    <div className="flex-grow-1 d-flex flex-column align-items-start">
                                        <h5 className="mb-2">{a.intake.specialty.college.fullName}</h5>
                                        <p className="mb-3">
                                            <span className="fw-lighter">
                                                {a.intake.specialty.specialty.code}
                                            </span>
                                            - {a.intake.specialty._instanceName}
                                            {a.intake.specialtyBranch && a.intake.specialtyBranch.length &&
                                            " (" + a.intake.specialtyBranch + ")"
                                            }
                                        </p>
                                        <div className="mb-3 text-secondary d-flex flex-wrap intake-params">
                                            {a.intake.budgetType &&
                                            <div>
                                                {a.intake.budgetType === "BT_BUDGET" && "Бюджетный"}
                                                {a.intake.budgetType === "BT_COMMERCIAL" && "Платный"}
                                                , мест: {a.intake.numberOfVacancies}
                                            </div>
                                            }
                                            {a.intake.year &&
                                            <div>
                                                Учебный год: {a.intake.year}
                                            </div>
                                            }
                                            {a.intake.specialty.studyForm &&
                                            <div>
                                                {a.intake.specialty.studyForm === "SF_FULLTIME" && "Очная форма обучения"}
                                                {a.intake.specialty.studyForm === "SF_CORRESPONDENCE" && "Очно-заочная форма обучения"}
                                                {a.intake.specialty.studyForm === "SF_EVENING" && "Заочная форма обучения"}
                                            </div>
                                            }
                                            {a.intake.studyLength &&
                                            <div>
                                                {a.intake.studyLength && "Продолжительность обучения: " + a.intake.studyLength}
                                            </div>
                                            }
                                        </div>
                                        <div className="intake-container">
                                            <div className="d-flex flex-wrap">
                                                {a.intake.tests && a.intake.tests.length > 0 &&
                                                <div className="details">
                                                    <FontAwesomeIcon icon={faPersonChalkboard} className="me-2"
                                                                     color="#0094ff"/> Вступительные
                                                    испытания <Badge>{a.intake.tests.length}</Badge>
                                                </div>
                                                }
                                                {a.intake.specialty.medCertRequired &&
                                                <div className="details">
                                                    <FontAwesomeIcon
                                                        icon={faFileContract}
                                                        className="me-2"
                                                        color="#0094ff"/> Справка о состоянии здоровья</div>
                                                }
                                                {a.dormitory &&
                                                <div className="details">
                                                    <FontAwesomeIcon
                                                        icon={faBuilding}
                                                        className="me-2"
                                                        color="#0094ff"/> Необходимо общежитие</div>
                                                }
                                                {a.entitlement &&
                                                <div className="details">
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="me-2"
                                                        color="#0094ff"/> Особые условия поступления</div>
                                                }
                                                {a.underContract &&
                                                <div className="details">
                                                    <FontAwesomeIcon
                                                        icon={faFile}
                                                        className="me-2"
                                                        color="#0094ff"/> Договор о целевом обучении</div>}
                                            </div>
                                            {a.intake.intakePeriodStart && a.intake.intakePeriodEnd &&
                                            <div className="d-flex align-items-center fw-light mt-2">
                                                <FontAwesomeIcon icon={faCalendar} className={'me-2'} color="#0094ff"/>
                                                <p className="mb-0 text-secondary small">
                                                    Период набора:
                                                    c {new Date(a.intake.intakePeriodStart).toLocaleDateString()} по {new Date(a.intake.intakePeriodEnd).toLocaleDateString()}
                                                </p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex flex-lg-column">
                                        <div className="mb-2">
                                            Статус:  <b style={{color: color}}>{status}</b>
                                        </div>
                                        <div className="text-secondary small mb-2">
                                            Дата изменения статуса: {new Date(a.statusDate).toLocaleDateString()}
                                        </div>
                                        <div  className="text-secondary small mb-2">Создано {new Date(a.createdDate).toLocaleDateString()} в {new Date(a.createdDate).toLocaleTimeString()}</div>
                                        {a.status !== "AS_RAFT" && a.status !== "AS_REFUSED" &&
                                        <Button variant="outline-danger"
                                                onClick={() => this.revoke(a.id)}>Отозвать</Button>
                                        }
                                    </div>
                                </div>
                            )
                        })
                            : this.state.filters?
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center text-secondary fst-italic"
                                 style={{minHeight: 460}}> Ничего не найдено </div>
                            :
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center text-secondary fst-italic"
                                 style={{minHeight: 460}}> Вы еще не подали ни одного заявления </div>
                        :
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center text-primary"
                             style={{minHeight: 460}}>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(Applications);
