import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import EventBus from "../../common/EventBus";
import {connect} from "react-redux";
import {Alert, Navbar, NavDropdown} from "react-bootstrap";

class TopMenu extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <Navbar expand="lg" bg="none">
                    <div className="container-fluid">
                        <Link to={"/"} className="navbar-brand order-0">
                            <img
                                alt='Logo'
                                src={process.env.PUBLIC_URL + '/resources/images/app-logo.svg'}
                                height="40"
                            />
                        </Link>
                        <Navbar.Collapse id="top-menu" className="order-2 order-lg-1">
                            <ul className="navbar-nav me-auto mb-2 mt-4 mb-lg-0 mt-lg-0">
                                <li className="nav-item">
                                    <NavLink to={"/"} isActive={(match, location) => {
                                        if (location && (location.pathname === "/" || location.pathname.indexOf("admission") !== -1)) {
                                            return true;
                                        }
                                        return false
                                    }} className="nav-link">
                                        {this.props.auth ? "Поступление" : "Наборы"}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"/info"} activeClassName="active" className="nav-link">
                                        Порядок поступления
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"/about"} activeClassName="active" className="nav-link">
                                        О проекте
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"/contacts"} activeClassName="active" className="nav-link">
                                        Контакты
                                    </NavLink>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                        <div className="navbar-nav user-nav ml-auto flex-row order-1 order-lg-2">
                            {this.props.auth && this.props.user && this.props.user.isLoaded ? (
                                <>
                                    <li className="nav-item">
                                        <div className="nav-link">
                                            <img className="user-image"
                                                 src={process.env.PUBLIC_URL + '/resources/images/icons/log-in.svg'}
                                                 height="34"/>
                                            {this.props.user.isLoaded ? this.props.user.data.firstName + " " + this.props.user.data.lastName : ""//
                                                //this.props.user.isLoaded ? "Здесь": "Не здесь"
                                            }
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/"} className="nav-link log-out"
                                              onClick={() => EventBus.dispatch("logout")}>
                                            <img
                                                alt='Выйти'
                                                src={process.env.PUBLIC_URL + '/resources/images/icons/log-out.svg'}
                                                height="16"
                                            />
                                        </Link>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item login">
                                        <img
                                            src={process.env.PUBLIC_URL + '/resources/images/icons/log-in.svg'}
                                            height="40"/>
                                        <Link to={"/login"}>Вход</Link><Link to={"/register"}>Регистрация</Link>
                                    </li>
                                </>
                            )}
                            <Navbar.Toggle aria-controls="top-menu"/>
                        </div>
                    </div>
                </Navbar>
        );
    }
}

function mapStateToProps(state) {
    const {auth} = state.auth;
    const user = state.user;
    return {
        auth,
        user
    };
}

export default connect(mapStateToProps)(TopMenu);
