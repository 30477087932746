import React, {Component} from "react";
import {Form, Button, InputGroup, ButtonGroup, ToggleButton, Alert} from "react-bootstrap";
import {connect} from "react-redux";
import {register} from "../actions/auth";
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Datepicker, {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import {
    faAt, faMale, faCheck, faChevronRight,
    faFemale, faKey, faChevronLeft, faTimes,
    faBookAtlas, faCalendar, faLocation, faPaperPlane, faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import EventBus from "../common/EventBus";

registerLocale('ru', ru);
const options = [
    {label: 'Мужской', value: "SEX_MAN", icon: <FontAwesomeIcon icon={faMale}/>},
    {label: 'Женский', value: "SEX_WOMAN", icon: <FontAwesomeIcon icon={faFemale}/>}
];
const passRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-._!@#$%^&*])(?=.{8,})");

class Register extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.state = {
            loading: false,
            email: "",
            password: "",
            surName: "",
            firstName: "",
            lastName: "",
            sex: "SEX_MAN",
            documentType: 1,
            documentNumber: "",
            documentSeries: "",
            documentDate: null,
            documentPlace: "",
            documentPlaceCode: "",
            birthDate: "",
            page: 1,
            error: {
                email: false,
                password: false,
                surName: false,
                firstName: false,
                documentNumber: false,
                documentDate: false,
                documentPlace: false,
            },
            serverError: false,
            serverErrorData: null,
            existError: false,
            existEmail: null
        };
    }

    componentDidMount() {

    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    validateEmail = async () => {
        if (!this.state.email || !this.state.email.length) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    email: "Поле обязательно к заполнению"
                }
            });
            return false;
        }
        const regexp = /^[\w.%+-]+@[\w.-]+\.[\w]{2,18}$/;
        if (!regexp.test(this.state.email)) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    email: "Некоректный адрес электронной почты"
                }
            });
            return false;
        }
        if (this.state.existEmail && this.state.existEmail === this.state.email) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    email: "Данный email уже используется"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                email: false
            }
        });
        return true;
    };
    validatePassword = async () => {
        if (!this.state.password || !this.state.password.length) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    password: "Поле обязательно к заполнению"
                }
            });
            return false;
        }
        if (!passRegExp.test(this.state.password)) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    password: "Некорректный пароль"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                password: false
            }
        });
        return true;
    };
    validateFirstName = async () => {
        if (!this.state.firstName || !this.state.firstName.length) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    firstName: "Поле обязательно к заполнению"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                firstName: false
            }
        });
        return true;
    };
    validateSurName = async () => {
        if (!this.state.surName || !this.state.surName.length) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    surName: "Поле обязательно к заполнению"
                }
            });
            return false;
        }

        await this.setStateAsync({
            error: {
                ...this.state.error,
                surName: false
            }
        });

        return true;
    };
    validateDocumentNumber = async () => {
        if (!this.state.documentNumber || !this.state.documentNumber.length) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    documentNumber: "Поле обязательно к заполнению"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                documentNumber: false
            }
        });
        return true;
    };
    validateDocumentDate = async () => {
        if (!this.state.documentDate) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    documentDate: "Поле обязательно к заполнению"
                }
            });
            return false;
        }
        if (!(this.state.documentDate instanceof Date)) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    documentDate: "Некорректная дата"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                documentDate: false
            }
        });
        return true;
    };
    validateBirthDate = async () => {
        if (!this.state.birthDate) {
            let error = this.state.error;
            await this.setStateAsync({
                error: {...this.state.error, birthDate: "Поле обязательно к заполнению"}
            });
            return false;
        }
        if (!(this.state.birthDate instanceof Date)) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    birthDate: "Некорректная дата"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                birthDate: false
            }
        });
        return true;
    };
    validateDocumentPlace = async () => {
        if (!this.state.documentPlace) {
            await this.setStateAsync({
                error: {
                    ...this.state.error,
                    documentPlace: "Поле обязательно к заполнению"
                }
            });
            return false;
        }
        await this.setStateAsync({
            error: {
                ...this.state.error,
                documentPlace: false
            }
        });
        return true;
    };

    handleNext = async (e) => {
        e.preventDefault();
        await this.validateEmail();
        await this.validatePassword();
        await this.validateFirstName();
        await this.validateSurName();
        if (!this.state.error.email
            && !this.state.error.password
            && !this.state.error.firstName
            && !this.state.error.surName) {
            this.setState({
                page: 2
            });
        }
    };
    validateAll = async () => {
        let result = true;
        let emailValid = await this.validateEmail();
        if (!emailValid) {
            result = false;
            this.setState({page: 1})
        }
        let passwordValid = await this.validatePassword();
        if (!passwordValid) {
            result = false;
            this.setState({page: 1})
        }
        let surnameValid = await this.validateSurName();
        if (!surnameValid) {
            result = false;
            this.setState({page: 1})
        }
        let firstNameValid = await this.validateFirstName();
        if (!firstNameValid) {
            result = false;
            this.setState({page: 1})
        }
        let birthDateValid = await this.validateBirthDate();
        if (!birthDateValid) {
            result = false;
        }
        let docNum = await this.validateDocumentNumber();
        if (!docNum) {
            result = false;
        }
        let docDate = await this.validateDocumentDate();
        if (!docDate) {
            result = false;
        }
        let docPlace = await this.validateDocumentPlace();
        if (!docPlace) {
            result = false;
        }
        return result;
    };
    handleRegister = async (e) => {
        e.preventDefault();
        let formIsValid = await this.validateAll();
        if (!formIsValid) {
            return;
        }
        this.setState({
            loading: true
        });
        let documentDateString = new Date(this.state.documentDate.getTime() - (this.state.documentDate.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        let birthDateString = new Date(this.state.birthDate.getTime() - (this.state.birthDate.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        let user = {
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            timeZoneId: "Europe/Moscow"
        };
        let person = {
            lastName: this.state.surName,
            firstName: this.state.firstName,
            middleName: this.state.lastName,
            sex: this.state.sex,
            birthDate: birthDateString,
            passport:
                {
                    serie: this.state.documentSeries,
                    number: this.state.documentNumber,
                    date: documentDateString,
                    issuer: this.state.documentPlace,
                },
            treatmentConsent: true
        };
        this.props
            .dispatch(
                register(user, person)
            )
            .then(() => {
                this.setState({
                    successful: true,
                    loading: false,
                    page: 3
                });
            })
            .catch((data) => {
                setTimeout(() => {
                    if (!data || (data && !data.result) || (data && data.result && data.result !== "ERROR")) {
                        this.setState({
                            successful: false,
                            loading: false,
                            serverError: true,
                            serverErrorData: "Произошла неизвестная ошибка"
                        });
                    } else {
                        if (!data.cause) {
                            if (!data.error) {
                                this.setState({
                                    successful: false,
                                    loading: false,
                                    serverError: true,
                                    serverErrorData: "Ошибка регистрации. Отправлены не все необходимые данные"
                                });
                            } else {
                                if (data.error === "password") {
                                    this.setState({
                                        successful: false,
                                        loading: false,
                                        page: 1,
                                        error: {
                                            ...this.state.error,
                                            password: "Придумайте корректный пароль"
                                        }
                                    });
                                }
                                if (data.error === "email") {
                                    this.setState({
                                        successful: false,
                                        loading: false,
                                        page: 1,
                                        error: {
                                            ...this.state.error,
                                            email: "Не корректный email"
                                        }
                                    });
                                }
                            }
                        } else {
                            if (data.cause === 'SERVER') {
                                if (data.data && data.data.status) {
                                    let errorText = "Ошибка " + data.data.status + "! ";
                                    if (data.data.statusText && data.data.statusText.length) {
                                        errorText += data.data.statusText;
                                    } else if (data.data.data && data.data.data.error) {
                                        errorText += data.data.data.error;
                                    }
                                    this.setState({
                                        successful: false,
                                        loading: false,
                                        serverError: true,
                                        serverErrorData: errorText
                                    });
                                } else {
                                    this.setState({
                                        successful: false,
                                        loading: false,
                                        serverError: true,
                                        serverErrorData: "Произошла неизвестная ошибка"
                                    });
                                }
                            }
                            else if (data.cause === 'exist') {
                                if (data.error && data.error === 'email' || data.error === 'username') {
                                    this.setState({
                                        successful: false,
                                        loading: false,
                                        page: 1,
                                        error: {
                                            ...this.state.error,
                                            email: "Данный email уже используется"
                                        },
                                        existError: true,
                                        existEmail: this.state.email
                                    });
                                } else {
                                    this.setState({
                                        successful: false,
                                        loading: false,
                                        serverError: true,
                                        serverErrorData: "Произошла неизвестная ошибка"
                                    });
                                }
                            }

                        }
                    }


                }, 300)
            });
    };

    openDatepicker = () => this._calendar.setOpen(true);
    openDatepickerBirthDate = () => this._calendarBirthDate.setOpen(true);
    handleEmailHelpClick = () => {
        EventBus.dispatch("emailConfirmHelp");
    };
    loginToSystem = (e) => {
        e.preventDefault();
        const {history} = this.props;
        history.push('/login');
    };

    render() {
        const {message} = this.props;
        let addMonths = (numOfMonths, date = new Date()) => {
            date.setMonth(date.getMonth() + numOfMonths);
            return date;
        };

        let letterTest = new RegExp("[A-Z]").test(this.state.password) && new RegExp("[a-z]").test(this.state.password);
        let numberTest = new RegExp("[0-9]").test(this.state.password);
        let symbolTest = new RegExp("[-._!@#$%^&*]").test(this.state.password);

        return (
            <section style={{minHeight: "100vh"}}>
                <div className="position-fixed d-lg-block d-none h-100 w-25 register-banner"
                     style={{backgroundImage: 'url("' + process.env.PUBLIC_URL + '/resources/images/register-background.png")'}}/>
                <div className="container-fluid h-100">
                    <div className="row min-vh-100 d-flex align-items-center">
                        <div className="d-lg-block d-none col-lg-3 position-relative min-vh-100">
                            <Link to={"/"}>
                                <img
                                    alt='Logo'
                                    className="mb-5 m-auto d-block mt-5"
                                    src={process.env.PUBLIC_URL + '/resources/images/app-logo-white.svg'}
                                    height="50"
                                />
                            </Link>
                        </div>
                        <div className="col-12 col-lg-9 col-xl-7 offset-xl-1 p-5">
                            <Row>
                                <Col xs={12} className="d-lg-none">
                                    <img
                                        alt='Logo'
                                        className="mb-2 d-block mt-5"
                                        src={process.env.PUBLIC_URL + '/resources/images/app-logo.svg'}
                                        height="50"
                                    />
                                </Col>
                                <Col xs={12}>
                                    <h1 className="mt-5 mb-1 slab">
                                        { this.state.page === 3 ? "Поздравляем!" : "Регистрация" }
                                    </h1>
                                    <p className="mb-2">
                                        {this.state.page === 1 ? "1. Заполните данные для входа"
                                            : this.state.page === 2 ? "2. Укажите данные документа, удостоверяющего личность"
                                                : this.state.page === 3 ? "Ваш аккаунт успешно зарегистрирован в системе" : null}
                                    </p>
                                </Col>
                                <Col xs={12} className="mt-3">
                                    {this.state.page === 1 &&
                                    <Form className={'register-form'}>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <FloatingLabel className={'mb-4 input-group required'}
                                                               label="Электронная почта">
                                                    <input placeholder={"Электронная почта"}
                                                           className={this.state.error.email ? "is-invalid form-control" : "form-control"}
                                                           value={this.state.email}
                                                           id={'email'}
                                                           name={'email'}
                                                           onChange={(e) => {
                                                               this.setState({
                                                                   email: e.target.value,
                                                                   error: {...this.state.error, email: false}
                                                               })
                                                           }}
                                                           disabled={this.state.loading}
                                                           onBlur={this.validateEmail}
                                                           required
                                                           type="email"/>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faAt}/>
                                                    </InputGroup.Text>
                                                    {this.state.error.email && <Form.Control.Feedback type="invalid"
                                                                                                      tooltip>{this.state.error.email}</Form.Control.Feedback>}
                                                </FloatingLabel>
                                                <FloatingLabel className={'mb-4 input-group required'} label="Пароль">
                                                    <input
                                                        className={this.state.error.password ? "is-invalid form-control" : "form-control"}
                                                        value={this.state.password}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                password: e.target.value,
                                                                error: {...this.state.error, password: false}
                                                            })
                                                        }}
                                                        onBlur={this.validatePassword}
                                                        placeholder={'Пароль'}
                                                        disabled={this.state.loading}
                                                        required
                                                        type="password"/>
                                                    {this.state.error.password &&
                                                    <Form.Control.Feedback type="invalid" className="top-60"
                                                                           tooltip>{this.state.error.password}</Form.Control.Feedback>}
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faKey}/>
                                                    </InputGroup.Text>
                                                    <Form.Text muted>
                                                        <ul>
                                                            <li>
                                                                {this.state.password && this.state.password.length < 8 ?
                                                                    <FontAwesomeIcon icon={faTimes}/>
                                                                    :
                                                                    <FontAwesomeIcon icon={faCheck}/>
                                                                }
                                                                Не менее 8 символов
                                                            </li>
                                                            <li>
                                                                {letterTest ?
                                                                    <FontAwesomeIcon icon={faCheck}/>
                                                                    :
                                                                    <FontAwesomeIcon icon={faTimes}/>
                                                                }
                                                                Минимум одна строчная и одна прописная латинска буква
                                                            </li>
                                                            <li>
                                                                {numberTest ?
                                                                    <FontAwesomeIcon icon={faCheck}/>
                                                                    :
                                                                    <FontAwesomeIcon icon={faTimes}/>
                                                                }
                                                                Минимум одна арабская цифра
                                                            </li>
                                                            <li>
                                                                {symbolTest ?
                                                                    <FontAwesomeIcon icon={faCheck}/>
                                                                    :
                                                                    <FontAwesomeIcon icon={faTimes}/>
                                                                }
                                                                Не менее одного специального символа -._!@#$%^&*
                                                            </li>
                                                        </ul>
                                                    </Form.Text>
                                                </FloatingLabel>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <FloatingLabel className={'mb-4 required'} label="Фамилия">
                                                    <input
                                                        className={this.state.error.surName ? "is-invalid form-control" : "form-control"}
                                                        placeholder="Фамилия"
                                                        value={this.state.surName}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                surName: e.target.value,
                                                                error: {...this.state.error, surName: false}
                                                            })
                                                        }}
                                                        onBlur={this.validateSurName}
                                                        required
                                                        disabled={this.state.loading}
                                                        type="text"/>
                                                    {this.state.error.surName &&
                                                    <Form.Control.Feedback type="invalid" className="top-60"
                                                                           tooltip>{this.state.error.surName}</Form.Control.Feedback>}
                                                </FloatingLabel>
                                                <FloatingLabel className={'mb-4 required'} label="Имя">
                                                    <input
                                                        className={this.state.error.firstName ? "is-invalid form-control" : "form-control"}
                                                        value={this.state.firstName}
                                                        placeholder={'Имя'}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                firstName: e.target.value,
                                                                error: {...this.state.error, firstName: false}
                                                            })
                                                        }}
                                                        onBlur={this.validateFirstName}
                                                        required
                                                        disabled={this.state.loading}
                                                        type="text"/>
                                                    {this.state.error.firstName &&
                                                    <Form.Control.Feedback type="invalid" className="top-60"
                                                                           tooltip>{this.state.error.firstName}</Form.Control.Feedback>}
                                                </FloatingLabel>
                                                <FloatingLabel className={'mb-4'} label="Отчество">
                                                    <input className={'form-control'}
                                                           value={this.state.lastName}
                                                           placeholder="Отчество"
                                                           disabled={this.state.loading}
                                                           onChange={(e) => {
                                                               this.setState({lastName: e.target.value})
                                                           }}
                                                           type="text"/>
                                                </FloatingLabel>
                                            </Col>
                                            {
                                                this.state.existError &&
                                                <Col xs={12}>
                                                    <Alert variant="danger" className={'mb-4'}
                                                           onClose={() => this.setState({
                                                               existError: false
                                                           })} dismissible>
                                                        <p>
                                                            Электронная почта {this.state.existEmail} уже используется
                                                            для другого аккаунта. Для регистрации необходимо указать
                                                            уникальную электронную почту.
                                                        </p>
                                                        <hr/>
                                                        <p className="mb-0">
                                                            Если вы утратили доступ к этому аккаунту, <a
                                                            className={'alert-link'}>восстановите пароль</a>
                                                        </p>
                                                    </Alert>
                                                </Col>

                                            }
                                            <Col xs={12} className="justify-content-end d-flex">
                                                <Button variant="primary"
                                                        type="submit"
                                                        size="lg"
                                                        disabled={this.state.loading}
                                                        onClick={(e) => {
                                                            this.handleNext(e)
                                                        }}>
                                                    Далее <FontAwesomeIcon icon={faChevronRight}/>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    }
                                    {this.state.page === 2 &&
                                    <Form className={'register-form'}>
                                        <Row>
                                            <Col xs={12}>
                                                <Row>
                                                    <Col xs={12} sm={6} lg={6} xl={4} xxl={4}>
                                                        <FloatingLabel className={'mb-4 input-group required'}
                                                                       label="Пол">
                                                            <select placeholder={"Пол"}
                                                                    className={"form-control"}
                                                                    id={'sex'}
                                                                    name={'sex'}
                                                                    disabled={this.state.loading}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            sex: e.target.value
                                                                        })
                                                                    }}
                                                                    required>
                                                                <option
                                                                    value={options[0].value}>{options[0].label}</option>
                                                                <option
                                                                    value={options[1].value}>{options[1].label}</option>
                                                            </select>
                                                            <InputGroup.Text>
                                                                {options.find(o => o.value === this.state.sex).icon}
                                                            </InputGroup.Text>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col xs={12} sm={6} lg={6} xl={4} xxl={4}>
                                                        <FloatingLabel
                                                            className={(this.state.error.birthDate && 'invalid') + ' mb-4 input-group required ' + (this.state.birthDate && 'filled')}
                                                            label={"Дата рождения"}>
                                                            <Datepicker
                                                                className={this.state.error.birthDate ? "is-invalid form-control" : "form-control"}
                                                                selected={this.state.birthDate}
                                                                dateFormat="dd MMMM yyyy"
                                                                locale={'ru'}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                minDate={new Date().setMonth(new Date().getMonth() - 720)}
                                                                maxDate={new Date().setMonth(new Date().getMonth() - 120)}
                                                                dropdownMode="select"
                                                                disabled={this.state.loading}
                                                                onChange={async (date) => {
                                                                    await this.setState({birthDate: date});
                                                                    await this.validateBirthDate()
                                                                }}
                                                                onBlur={async () => this.validateBirthDate()}
                                                                ref={(c) => this._calendarBirthDate = c}
                                                                required/>
                                                            <InputGroup.Text onClick={this.openDatepickerBirthDate}>
                                                                <FontAwesomeIcon icon={faCalendar}/>
                                                            </InputGroup.Text>
                                                            {this.state.error.birthDate &&
                                                            <Form.Control.Feedback type="invalid"
                                                                                   tooltip>{this.state.error.birthDate}</Form.Control.Feedback>}
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col xs={12} sm={6} lg={6} xl={4} xxl={4}>
                                                        <FloatingLabel className={'mb-4'}
                                                                       label="Место рождения">
                                                            <input placeholder={"Место рождения"}
                                                                   className={"form-control"}
                                                                   disabled={this.state.loading}
                                                                   value={this.state.documentPlaceCode}
                                                                   onChange={(e) => {
                                                                       this.setState({
                                                                           documentPlaceCode: e.target.value
                                                                       })
                                                                   }}
                                                                   required
                                                                   type="text"/>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <label>Тип документа:</label>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <ButtonGroup name={'document-type'} className={'mb-4'}>
                                                            <ToggleButton
                                                                type="radio"
                                                                variant="outline-primary"
                                                                name="radio"
                                                                disabled={this.state.loading}
                                                                value={1}
                                                                checked={this.state.documentType === 1}
                                                                onClick={(e) => this.setState({documentType: 1})}>
                                                                Внутренний паспорт РФ
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                type="radio"
                                                                variant="outline-primary"
                                                                name="radio"
                                                                disabled={this.state.loading}
                                                                value={2}
                                                                checked={this.state.documentType === 2}
                                                                onClick={(e) => this.setState({documentType: 2})}>
                                                                Свидетельство о рождении
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                type="radio"
                                                                variant="outline-primary"
                                                                name="radio"
                                                                disabled={this.state.loading}
                                                                value={3}
                                                                checked={this.state.documentType === 3}
                                                                onClick={(e) => this.setState({documentType: 3})}>
                                                                Паспорт иностранного гражданина
                                                            </ToggleButton>
                                                        </ButtonGroup>
                                                    </Col>
                                                    {this.state.documentType === 1 &&
                                                    <Col xs={12} sm={6} md={5} lg={5} xl={5} xxl={4}>
                                                        <FloatingLabel className={'mb-4'}
                                                                       label="Серия документа">
                                                            <input placeholder={"Серия документа"}
                                                                   className={"form-control"}
                                                                   disabled={this.state.loading}
                                                                   value={this.state.documentSeries}
                                                                   id={'document-series'}
                                                                   name={'document-series'}
                                                                   onChange={(e) => {
                                                                       this.setState({documentSeries: e.target.value})
                                                                   }}
                                                                   type="text"/>
                                                        </FloatingLabel>
                                                    </Col>
                                                    }
                                                    <Col xs={12} sm={6} md={7} lg={7} xl={7} xxl={6}>
                                                        <FloatingLabel className={'mb-4 input-group required'}
                                                                       label="Номер документа">
                                                            <input placeholder={"Номер документа"}
                                                                   className={this.state.error.documentNumber ? "is-invalid form-control" : "form-control"}
                                                                   value={this.state.documentNumber}
                                                                   disabled={this.state.loading}
                                                                   id={'document-number'}
                                                                   name={'document-number'}
                                                                   onChange={(e) => {
                                                                       this.setState({
                                                                           documentNumber: e.target.value,
                                                                           error: {
                                                                               ...this.state.error,
                                                                               documentNumber: false
                                                                           }
                                                                       })
                                                                   }}
                                                                   onBlur={this.validateDocumentNumber}
                                                                   required
                                                                   type="text"/>
                                                            <InputGroup.Text>
                                                                <FontAwesomeIcon icon={faBookAtlas}/>
                                                            </InputGroup.Text>
                                                            {this.state.error.documentNumber &&
                                                            <Form.Control.Feedback type="invalid"
                                                                                   tooltip>{this.state.error.documentNumber}</Form.Control.Feedback>}
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col xs={12} sm={6} md={5} lg={5} xl={5} xxl={4}>
                                                        <FloatingLabel
                                                            className={(this.state.error.documentDate && 'invalid') + ' mb-4 input-group required ' + (this.state.documentDate && 'filled')}
                                                            label={"Дата выдачи"}>
                                                            <Datepicker placeholder={"Дата выдачи"}
                                                                        className={this.state.error.documentDate ? "is-invalid form-control" : "form-control"}
                                                                        selected={this.state.documentDate}
                                                                        locale={'ru'}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        minDate={new Date().setMonth(new Date().getMonth() - 720)}
                                                                        maxDate={new Date()}
                                                                        dropdownMode="select"
                                                                        disabled={this.state.loading}
                                                                        onChange={async (date) => {
                                                                            await this.setStateAsync({documentDate: date});
                                                                            await this.validateDocumentDate()
                                                                        }
                                                                        }
                                                                        onBlur={async () => this.validateDocumentDate()}
                                                                        ref={(c) => this._calendar = c}
                                                                        required/>
                                                            <InputGroup.Text onClick={this.openDatepicker}>
                                                                <FontAwesomeIcon onClick={this.openDatepicker}
                                                                                 icon={faCalendar}/>
                                                            </InputGroup.Text>
                                                            {this.state.error.documentDate &&
                                                            <Form.Control.Feedback type="invalid"
                                                                                   tooltip>{this.state.error.documentDate}</Form.Control.Feedback>}
                                                        </FloatingLabel>
                                                    </Col>
                                                    {this.state.documentType === 1 &&
                                                    <Col xs={12} sm={6} md={7} lg={7} xl={7} xxl={6}>
                                                        <FloatingLabel className={'mb-4'}
                                                                       label="Код подразделения">
                                                            <input placeholder={"Код подразделения"}
                                                                   className={"form-control"}
                                                                   value={this.state.documentPlaceCode}
                                                                   disabled={this.state.loading}
                                                                   id={'document-place-code'}
                                                                   name={'document-place-code'}
                                                                   onChange={(e) => {
                                                                       this.setState({
                                                                           documentPlaceCode: e.target.value
                                                                       })
                                                                   }}
                                                                   required
                                                                   type="text"/>
                                                        </FloatingLabel>
                                                    </Col>
                                                    }
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={10}>
                                                        <FloatingLabel className={'mb-4 input-group required'}
                                                                       label="Кем выдан">
                                                            <input placeholder={"Кем выдан"}
                                                                   className={this.state.error.documentPlace ? "is-invalid form-control" : "form-control"}
                                                                   value={this.state.documentPlace}
                                                                   id={'document-number'}
                                                                   disabled={this.state.loading}
                                                                   name={'document-number'}
                                                                   onChange={(e) => {
                                                                       this.setState({
                                                                           documentPlace: e.target.value,
                                                                           error: {
                                                                               ...this.state.error,
                                                                               documentPlace: false
                                                                           }
                                                                       })
                                                                   }}
                                                                   onBlur={this.validateDocumentPlace}
                                                                   required
                                                                   type="text"/>
                                                            <InputGroup.Text>
                                                                <FontAwesomeIcon icon={faLocation}/>
                                                            </InputGroup.Text>
                                                            {this.state.error.documentPlace &&
                                                            <Form.Control.Feedback type="invalid"
                                                                                   tooltip>{this.state.error.documentPlace}</Form.Control.Feedback>}
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                this.state.serverError &&
                                                <Col xs={12}>
                                                    <Alert variant="danger" className={'mb-4'}
                                                           onClose={() => this.setState({
                                                               serverError: false,
                                                               serverErrorData: null
                                                           })} dismissible>
                                                        <Alert.Heading>
                                                            {this.state.serverErrorData}
                                                        </Alert.Heading>
                                                        <p>
                                                            Попробуйте повторить регистрацию позднее. Если ошибка
                                                            произойдет снова, обратитесь в техническую поддержку
                                                        </p>
                                                        <hr/>
                                                        <p className="mb-0">
                                                            Техническая поддержка: <a className={'alert-link'}
                                                                                      href={'mailto:ecollege@it-strategy.center'}>ecollege@it-strategy.center</a>
                                                        </p>
                                                    </Alert>
                                                </Col>

                                            }
                                            <Col xs={6} className="">
                                                <Button variant="secondary"
                                                        size="lg"
                                                        disabled={this.state.loading}
                                                        onClick={() => {
                                                            this.setState({page: 1})
                                                        }}>
                                                    <FontAwesomeIcon icon={faChevronLeft}/> Назад
                                                </Button>
                                            </Col>
                                            <Col xs={6} className="justify-content-end d-flex">
                                                <Button variant="primary"
                                                        size="lg"
                                                        disabled={this.state.loading}
                                                        onClick={(e) => {
                                                            this.handleRegister(e)
                                                        }}>
                                                    {this.state.loading ?
                                                        <span className="spinner-border spinner-border-sm me-1"/>
                                                        :
                                                        <FontAwesomeIcon className="me-1" icon={faPaperPlane}/>
                                                    }
                                                    Отправить
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    }
                                    {this.state.page === 3 &&
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={8} className={'mb-3'}>
                                            <p>
                                                В течение нескольких минут на электронную почту {this.state.email}, указанную при регистрации, придет письмо со ссылкой для подтверждения вашего e-mail.
                                            </p>
                                            <p className={'mb-5'}>
                                                 Откройте письмо и перейдите по ссылке, указанной в письме.
                                            </p>
                                            <Alert variant="warning" className={'mb-5'}>
                                                <FontAwesomeIcon icon={faInfoCircle} className={'me-2'}/>  Не получили письмо? <Alert.Link onClick={this.handleEmailHelpClick}>Узнайте о возможных проблемах</Alert.Link>
                                            </Alert>
                                            <Button variant="primary"
                                                    type="submit"
                                                    size="lg"
                                                    disabled={this.state.loading}
                                                    onClick={(e) => {
                                                        this.loginToSystem(e)
                                                    }}>
                                                <FontAwesomeIcon icon={faChevronRight}/> Войти в систему
                                            </Button>

                                        </Col>
                                    </Row>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

function mapStateToProps(state) {
    const {message} = state.message;
    const {auth} = state.auth;
    return {
        message,
    };
}

export default connect(mapStateToProps)(Register);
