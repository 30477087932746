import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    REFRESH_TOKEN
} from "./types";
import {processError} from "../services/api"
import AuthService from "../services/auth.service";

export const register = (username, email, password) => (dispatch) => {
    return AuthService.register(username, email, password).then(
        (response) => {
            if (!response || !response.data || !response.data.result) {
                return Promise.reject(false);
            } else if (response.data.result && response.data.result === 'ERROR') {
                return Promise.reject(response.data);
            }
            return Promise.resolve(response.data);
        },
        (error) => {
            return Promise.reject({
                result: 'ERROR',
                cause: "SERVER",
                data: error.response ? error.response : false
            });
        }
    );
};

export const confirmEmail = (token) => (dispatch) => {
    return AuthService.confirmEmail(token).then(
        (data) => {
            if (data.data && data.data.result && data.data.result === "OK") {
                return Promise.resolve(data.data);
            } else {
                if (data.data && data.data.error) {
                    if (data.data.error === 'token') {
                        return Promise.reject({
                            header: "Ошибка подтверждения email",
                            text: "Некорекктный или устарвеший токен"
                        });
                    }
                    else if (data.data.error === 'profile') {
                        return Promise.reject({
                            header: "Ошибка подтверждения email",
                            text: "Не возможно создать профиль пользователя. Обратитесь в техническую поддержку"
                        });
                    }
                    else {
                        return Promise.reject({
                            header: "Ошибка подтверждения email",
                            text: data.data.error
                        });
                    }
                }
                else {
                    return Promise.reject({
                        header: "Произошла неизвестная ошибка",
                        text: "Попробуйте позже или обратитесь в поддержку"
                    });
                }
            }
        },
        (error) => {
            let err = processError(error);
            return Promise.reject(err);
        }
    )
};

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username.toUpperCase(), password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {auth: data}
            });
            return Promise.resolve();
        },
        (error) => {
            let message = '';
            if (error.response) {
                if (error.response.data) {
                    if (error.response.data.error_description) {
                        message = error.response.data.error_description;
                    } else if (error.response.data.message) {
                        message = error.response.data.message;
                    } else (
                        message = error.response.data.toString()
                    )
                } else {
                    if (error.response.status) {
                        message = 'Произошла ошибка ' + error.response.status
                    } else {
                        message = 'Произошла неизвестная ошибка';
                    }
                }
            } else if (error.request) {
                message = 'Нет соединения с сервером';
            } else {
                message = 'Произошла неизвестная ошибка';
            }
            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT,
    });
};

export const refreshToken = (accessToken) => (dispatch) => {
    dispatch({
        type: REFRESH_TOKEN,
        payload: accessToken,
    })
};
