import api, {serverUrl} from "./api";
import TokenService from "./token.service";
import Qs from "qs";
import axios from "axios";

class AuthService {
    login(username, password) {
        let data = Qs.stringify({
            'grant_type': 'password',
            'username': username,
            'password': password
        });
        return api.post("/oauth/token", data).then(response => {
            if (response.data.access_token) {
                let mappedData = {};
                mappedData.accessToken = response.data.access_token;
                mappedData.refreshToken = response.data.refresh_token;
                TokenService.setUser(mappedData);
                return mappedData;
            }
            return response;
        });
    }

    logout() {
        TokenService.removeUser();
    }

    register(user, person) {
        let config = {
            method: 'post',
            url: serverUrl + "/rest/registration/register",
            headers: {
                'Content-Type': 'application/json'
            },
            data : {
                user: user,
                person: person
            }
        };
        return axios(config);
    }
    confirmEmail(token) {
        return api.get("rest/registration/confirm?token=" + token).then(response => {
            return response;
        });
    }
}

export default new AuthService();
