import React, {Component} from "react";
import {Redirect} from 'react-router-dom';
import {Badge, InputGroup, FormControl, Form, Row, Col, Button, Modal, Spinner} from 'react-bootstrap';
import {connect} from "react-redux";
import {getCollegeDocuments, getIntake, getIntakeDocuments, getIntakeSubjects} from "../actions/intakes";
import {
    getUser,
    getPersonDocuments,
    deleteUserDocument,
    updateUserPerson,
    getUserMarks,
    createUserMarks, deleteUserMarks, updateUserMarks, createApplicant
} from "../actions/user";
import {
    faBuilding,
    faCalendar, faCheck, faFile,
    faFileContract, faFileExcel, faFilePdf, faFileWord, faPaperPlane, faPencil,
    faPersonChalkboard,
    faPlus, faTimes, faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {serverUrl} from "../services/api";
import TokenService from "../services/token.service";
import Document from "./documents/document.modal.component";
import eventBus from "../common/EventBus";
import {toast} from 'react-toastify';
import Datepicker from "react-datepicker";
import {AddressSuggestions} from 'react-dadata';
import InputMask from 'react-input-mask';
import IntakeService from "../services/intake.service";


const documentTypes = [
    {
        id: "underContract",
        name: "Договор о целевом обучении",
        searchText: "Договор о целевом обучении",
        headerText: "Документы, подтверждающие наличие договора о целевом обучении"
    }, {
        id: "entitlement",
        name: "Документ, подтверждающий право на особые условия ВИ",
        searchText: "Документ, подтверждающий право на особые условия проведения вступительных испытаний (для инвалидов и лиц с ограниченными возможностями здоровья)",
        headerText: "Документ, подтверждающий право на особые условия проведения вступительных испытаний (для инвалидов и лиц с ограниченными возможностями здоровья)"
    }, {
        id: "medCert",
        name: "Медицинская справка о прохождении медицинского осмотра (обследования)",
        searchText: "Медицинская справка о прохождении медицинского осмотра (обследования)",
        headerText: "Медицинская справка о прохождении медицинского осмотра (обследования)"
    }, {
        id: "Achievements",
        name: "Документ, подтверждающий личные достижения",
        searchText: "Документы, подтверждающие достижения",
        headerText: "Документы, подтверждающие достижения"
    }, {
        id: "identification",
        name: "Документ, удостоверяющий личность",
        searchText: "Документ, удостоверяющий личность, гражданство",
        headerText: "Копии документов, удостоверяющих личность и гражданство",
    }, {
        id: "education",
        name: "Документ об образовании / квалификации",
        searchText: "Документы об образовании и (или) об образовании и о квалификации",
        headerText: "Документы об образовании и (или) об образовании и о квалификации",
    }
];

class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intakeLoading: true,
            intakeDetailsLoading: true,
            collegeDetailsLoading: true,
            intakeError: false,
            intakeErrorHeader: null,
            intakeErrorText: null,
            intake: null,
            allowRequest: true,
            intakeSubjects: [],
            intakeDocuments: [],
            collegeDocuments: [],
            person: null,
            applicant: {
                dormitory: false,
                entitlement: false,
                underContract: false,
                person: null,
                status: 'AS_CANDIDATE',
                statusDate: null,
                // Ознакомлен с лицензией на осуществление образовательной деятельности
                familiarWithLicense: false,
                // Ознакомлен со свидетельством о государственной аккредитации образовательной деятельности по образовательным программам и приложениям к ним
                familiarWithCertOfAccreditation: false,
                // согласен на обработку полученных в связи с приемом в образовательную организацию персональных данных поступающих
                personalDataProcessingConsent: false,
                // факт получения среднего профессионального образования впервые
                firstTime: false,
                // ознакомлен с уставом образовательной организации
                familiarWithCharter: false,
                // ознакомлен с датой предоставления оригинала документа об образовании и (или) документа об образовании и о квалификации
                familiarWithDeadlineOfOriginals: false
            },
            marks: [],
            subjects: [],
            showDocumentModal: false,
            documentModal: null,
            personEditBlock1: false,
            personLoadingBlock1: false,
            personEditBlock2: false,
            personLoadingBlock2: false,
            personEditBlock3: false,
            personLoadingBlock3: false,
            personEditBlock4: false,
            personLoadingBlock4: false,
            subjectMarksEdit: false,
            subjectMarksLoading: false,
            errorFirstName: false,
            errorLastName: false,
            errorSex: false,
            errorPassportNumber: false,
            errorPassportDate: false,
            errorPassportPlace: false,
            addressesEquals: false,
            applicantLoading: false,
            onDeleteDocument: false,

            famError1: false,
            famError2: false,
            famError3: false,
            famError4: false,
            famError5: false,
            famError6: false,
        };
    }

    componentDidMount() {
        const {intakeId} = this.props.match.params;
        this.getIntake(intakeId)
    }
    getIntake = (id) => {
        const {dispatch} = this.props;
        dispatch(getIntake(id))
            .then((data) => {
                if (data.data) {
                    this.setState({
                        intake: data.data,
                        intakeError: false,
                        intakeErrorHeader: null,
                        intakeErrorText: null
                    });
                    this.getUser();
                    this.getIntakeDocuments(id);
                } else {
                    this.setState({
                        intakeLoading: false,
                        intakeError: true,
                        intakeErrorHeader: null,
                        intakeErrorText: null,
                        intake: null
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    intakeLoading: false,
                    intakeError: true,
                    intakeErrorHeader: null,
                    intakeErrorText: null,
                    intake: null
                });
            });
    };
    getUser = () => {
        const id = this.props.user.data.id;
        const {dispatch} = this.props;
        dispatch(getUser(id))
            .then((data) => {
                if (data.data) {
                    this.setState({
                        person: data.data.person
                    });
                    if (this.getAddress(data.data.person.regAddress) && this.getAddress(data.data.person.regAddress).length &&
                        this.getAddress(data.data.person.regAddress) === this.getAddress(data.data.person.livingAddress)) {
                        this.setState({addressesEquals: true})
                    }
                    this.getPersonDocuments(data.data.person.id);

                } else {
                    this.setState({
                        intakeLoading: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    intakeLoading: false
                })
            })
    };
    updateUserPerson = (part) => {
        let person = {};
        if (part === 1) {
            this.setState({
                errorFirstName: false,
                errorLastName: false,
                errorSex: false,
                errorPassportNumber: false,
                errorPassportDate: false,
                errorPassportPlace: false
            });
            if (!this.state.person.lastName || !this.state.person.lastName.length) {
                this.setState({
                    errorLastName: true
                });
                return
            }
            if (!this.state.person.firstName || !this.state.person.firstName.length) {
                this.setState({
                    errorFirstName: true
                });
                return
            }
            person.firstName = this.state.person.firstName;
            person.codeSNILS = this.state.person.codeSNILS;
            person.middleName = this.state.person.middleName;
            person.lastName = this.state.person.lastName;
            person.birthDate = this.state.person.birthDate;
            person.sex = this.state.person.sex;
            person.codeINN = this.state.person.codeINN;
            person.birthPlace = this.state.person.birthPlace;
            person.citizenship = this.state.person.citizenship;
            person.nativeLanguage = this.state.person.nativeLanguage;
            this.setState({
                personLoadingBlock1: true
            })
        } else if (part === 2) {
            this.setState({
                errorFirstName: false,
                errorLastName: false,
                errorSex: false,
                errorPassportNumber: false,
                errorPassportDate: false,
                errorPassportPlace: false
            });
            if (!this.state.person.passport.number || !this.state.person.passport.number.length) {
                this.setState({errorPassportNumber: true});
                return;
            }
            if (!this.state.person.passport.date || !this.state.person.passport.date.length) {
                this.setState({errorPassportDate: true});
                return;
            }
            if (!this.state.person.passport.issuer || !this.state.person.passport.issuer.length) {
                this.setState({errorPassportPlace: true});
                return;
            }
            person.passport = this.state.person.passport;
            this.setState({
                personLoadingBlock2: true
            })
        } else if (part === 3) {
            person.regAddress = this.state.person.regAddress;
            delete person.regAddress.fullAddress;
            person.livingAddress = this.state.addressesEquals ? this.state.person.regAddress : this.state.person.livingAddress;
            delete person.livingAddress.fullAddress;
            person.phoneNumber = this.state.person.phoneNumber;
        } else if (part === 4) {
            person.avgCertGrade = this.state.person.avgCertGrade;
            person.education = Object.assign({}, this.state.person.education);
            delete person.education.institutionAddress;
        } else return;
        toast.promise(
            this.saveUserPerson(person, this.state.person.id),
            {
                pending: 'Сохранение...',
                success: 'Данные сохранены!',
                error: 'Ошибка при сохранении.',
            }
        )
            .then(() => {
                this.setState({
                    personEditBlock1: false,
                    personEditBlock2: false,
                    personEditBlock3: false,
                    personLoadingBlock1: false,
                    personLoadingBlock2: false,
                    personLoadingBlock3: false,

                    personEditBlock4: false,
                    personLoadingBlock4: false,

                    person: this.props.user.person
                });
                if (part === 3) {
                    this.checkAddressesEquals()
                }
            })
            .catch(() => {
                this.setState({
                    personEditBlock1: false,
                    personEditBlock2: false,
                    personEditBlock3: false,
                    personLoadingBlock1: false,
                    personLoadingBlock2: false,
                    personLoadingBlock3: false,

                    personEditBlock4: false,
                    personLoadingBlock4: false,
                });
            });

    };
    checkAddressesEquals = () => {
        if (this.getAddress(this.props.user.person.regAddress) && this.getAddress(this.props.user.person.regAddress).length &&
            this.getAddress(this.props.user.person.regAddress) === this.getAddress(this.props.user.person.livingAddress)) {
            this.setState({addressesEquals: true})
        } else {
            this.setState({addressesEquals: false})
        }
    };
    saveUserPerson = (person, id) => {
        const {dispatch} = this.props;
        return dispatch(updateUserPerson(person, id))
            .then(() => {
                return Promise.resolve();
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    };
    getPersonDocuments = (id) => {
        const {dispatch} = this.props;
        dispatch(getPersonDocuments(id))
            .then(() => {
                this.getPersonMarks()
            })
            .catch((error) => {
                this.getPersonMarks()
            })
    };
    getPersonMarks = () => {
        const {dispatch} = this.props;
        dispatch(getUserMarks(this.props.user.person.id))
            .then((data) => {
                this.getSubjects()
            })
            .catch((error) => {
                this.getSubjects()
            })
    };
    getSubjects = () => {
        IntakeService.getSubjects()
            .then((data) => {
                if (data.data) {
                    let subjects = data.data;
                    subjects.sort((a, b) => a.name.localeCompare(b.name));
                    this.setState({
                        subjects: subjects
                    });
                    this.processUserMarks();
                }
                this.setState({
                    intakeLoading: false
                })
            })
            .catch((error) => {
                this.setState({
                    intakeLoading: false
                })
            });
    };
    processUserMarks = () => {
        const {marks} = this.props.user;
        let currentMarks = [...marks];
        let stateMarks = [];
        this.state.subjects.forEach(s => {
            let mark = currentMarks.find(m => m.schoolSubject.id === s.id);
            if (mark) {
                let foundMark = Object.assign({}, mark);
                stateMarks.push(foundMark);
            } else {
                stateMarks.push({
                    grade: null,
                    person: {
                        id: this.props.user.person.id
                    },
                    schoolSubject: {
                        id: s.id,
                        name: s.name
                    }
                })
            }
        });
        this.setState({
            marks: stateMarks
        })
    };
    getIntakeDocuments = (id) => {
        const {dispatch} = this.props;
        dispatch(getIntakeDocuments(id))
            .then((data) => {
                this.setState({
                    intakeDocuments: data.data
                });
                this.getIntakeSubjects();
            })
            .catch((error) => {
                this.getIntakeSubjects()
            })
    };
    getIntakeSubjects = () => {
        const {dispatch} = this.props;
        dispatch(getIntakeSubjects(this.state.intake.specialty.id))
            .then((data) => {
                this.setState({
                    intakeSubjects: data.data
                });
                this.getCollegeDocuments();
            })
            .catch((error) => {
                this.getCollegeDocuments();
            })
    };
    getCollegeDocuments = () => {
        const {dispatch} = this.props;
        dispatch(getCollegeDocuments(this.state.intake.specialty.college.id))
            .then((data) => {
                this.setState({
                    collegeDocuments: data
                });
                this.setState({
                    intakeDetailsLoading: false
                })
            })
            .catch((error) => {
                this.setState({
                    intakeDetailsLoading: false
                })
            })
    };
    processFileRef = (ref) => {
        if (!ref) {
            return (
                <p className="fst-italic text-secondary mb-0">Скан-копия документа не загружена</p>
            )
        }
        let [url, name] = ref.split('?name=');
        name = decodeURI(name);
        let fileType = name.split('.').pop();
        return (
            <a href={serverUrl + "/rest/files?access_token=" + TokenService.getLocalAccessToken() + "&fileRef=" + url + '?name=' + encodeURIComponent(name) + "&attachment=true"}>{name}</a>
        )
    };
    processFileIcon = (ref) => {
        if (!ref) {
            return (
                <div className="doc-icon">
                    <FontAwesomeIcon icon={faFile} size={"xl"} color="#232527"/>
                </div>
            )
        }
        let [url, name] = ref.split('?name=');
        name = decodeURI(name);
        let fileType = name.split('.').pop();
        return (
            <div className="doc-icon">
                {
                    fileType === 'doc' || fileType === 'docx' ?
                        <FontAwesomeIcon icon={faFileWord} size={"xl"} color="#295394"/>
                        : fileType === 'xls' || fileType === 'xlsx' ?
                        <FontAwesomeIcon icon={faFileExcel} size={"xl"} color="#1f6f45"/>
                        : fileType === 'pdf' ?
                            <FontAwesomeIcon icon={faFilePdf} size={"xl"} color="#f23024"/> :
                            <FontAwesomeIcon icon={faFile} size={"xl"} color="#232527"/>}
            </div>
        )
    };
    addDocument = (document) => {
        this.setState({
            showDocumentModal: true,
            documentModal: {
                new: true,
                type: document
            }
        });
        this.addDocumentCloseListener();
    };
    editDocument = (id) => {
        if (id) {
            this.setState({
                showDocumentModal: true,
                documentModal: {
                    edit: true,
                    id: id
                }
            });
            this.addDocumentCloseListener();
        }
    };
    onDeleteDocument = (id) => {
        this.setState({
            onDeleteDocument: id
        })
    };
    deleteDocument = (id) => {
        const {dispatch} = this.props;
        return dispatch(deleteUserDocument(id))
            .then(() => {
                toast.success("Документ удален");
                this.setState({onDeleteDocument: false});
                return Promise.resolve()
            })
            .catch(() => {
                toast.error("Ошибка при удалении документа");
                this.setState({onDeleteDocument: false});
                return Promise.reject()
            })
    };

    addDocumentCloseListener = () => {
        eventBus.on('closeDocument', () => {
            this.setState({
                showDocumentModal: false,
            });
            eventBus.remove("logout");
            setTimeout(() => {
                this.setState({
                    documentModal: null,
                });
            }, 100)
        });
    };
    renderDocumentList = (index) => {
        const personDocuments = this.props.user.personDocuments;
        return (
            <div className="document-container mb-4">
                <p className="text-secondary fw-light">{documentTypes[index].headerText}</p>
                <div className="document-data">
                    {personDocuments.filter(d => d.type.name === documentTypes[index].searchText).map(document => {
                        return (
                            <div key={document.id} className="document d-flex align-items-center">
                                <div className="d-flex flex-grow-1 align-items-center">
                                    {this.processFileIcon(document.image)}
                                    <div>
                                        <p className="mb-0">
                                            {document.name ?
                                                document.name
                                                :
                                                "Документ без названия "
                                            }
                                        </p>
                                        <p className="mb-0 small text-secondary fst-italic">
                                            {document.docSeries && "Серия " + document.docSeries + " "}
                                            {document.docNumber && "№ " + document.docNumber}
                                            {(document.docNumber || document.docSeries) && (document.docDate || document.issuer) && ". "}
                                            {document.docDate && "Дата выдачи: " + new Date(document.docDate).toLocaleDateString()}
                                            {document.docDate && document.issuer && ". "}
                                            {document.issuer && "Выдал «" + document.issuer + "»"}
                                        </p>
                                        {this.processFileRef(document.image)}
                                    </div>
                                </div>
                                <div className="mt-2 mb-2">
                                    <Button variant="link" size="sm" className="mt-1 mb-1 fw-bolder"
                                            onClick={() => this.editDocument(document.id)}>Изменить</Button>
                                    <Button variant="link" size="sm" className="mt-1 mb-1 text-danger fw-bolder"
                                            onClick={() => this.onDeleteDocument(document.id)}>Удалить</Button>
                                </div>
                            </div>
                        )
                    })}
                    {!personDocuments.filter(d => d.type.name === documentTypes[index].searchText).length &&
                    <div className="document fst-italic text-secondary text-center pb-2">Вы еще не
                        згрузили ни один документ</div>
                    }
                </div>
                <div className="mt-3 d-flex justify-content-end">
                    <Button variant="link" size={"sm"} className="fw-bolder"
                            onClick={() => this.addDocument(documentTypes[index].searchText)}>
                        <FontAwesomeIcon icon={faPlus}/> Добавить
                    </Button>
                </div>
            </div>
        )
    };
    getAddress = (address) => {
        let list = [];
        if (address.postIndex) list.push(address.postIndex);
        if (address.country) list.push(address.country.name);
        if (address.region) list.push(address.region.name);
        if (address.city) list.push(address.city.name);
        if (address.street) list.push(address.street);
        if (address.apartmentNumber) list.push(address.apartmentNumber);
        if (address.building) list.push(address.building);
        if (address.houseNumber) list.push(address.houseNumber);
        return list.join(', ')
    };
    setAddress = (address, type) => {
        if (type === 'reg') {
            if (address) {
                this.setState({
                    person: {
                        ...this.state.person,
                        regAddress: {
                            // e.country
                            // e.city
                            // e.federal_district
                            postIndex: address.postal_code,
                            street: address.street_with_type,
                            houseNumber: address.house,
                            building: address.block,
                            apartmentNumber: address.flat,
                            fiasId: address.fias_id,
                            gpsLat: address.geo_lat,
                            gpsLon: address.geo_lon,
                        }
                    }
                });
                if (this.state.addressesEquals) {
                    this.setState({
                        person: {
                            ...this.state.person,
                            livingAddress: {
                                // e.country
                                // e.city
                                // e.federal_district
                                postIndex: address.postal_code,
                                street: address.street_with_type,
                                houseNumber: address.house,
                                building: address.block,
                                apartmentNumber: address.flat,
                                fiasId: address.fias_id,
                                gpsLat: address.geo_lat,
                                gpsLon: address.geo_lon,
                            }
                        }
                    });
                }
            } else {
                this.setState({
                    person: {
                        ...this.state.person,
                        regAddress: {
                            country: null,
                            city: null,
                            federal_district: null,
                            postIndex: null,
                            street: null,
                            houseNumber: null,
                            building: null,
                            apartmentNumber: null,
                            fiasId: null,
                            gpsLat: null,
                            gpsLon: null,
                        }
                    }
                });
                if (this.state.addressesEquals) {
                    this.setState({
                        person: {
                            ...this.state.person,
                            livingAddress: {
                                country: null,
                                city: null,
                                federal_district: null,
                                postIndex: null,
                                street: null,
                                houseNumber: null,
                                building: null,
                                apartmentNumber: null,
                                fiasId: null,
                                gpsLat: null,
                                gpsLon: null,
                            }
                        }
                    });
                }
            }
        }
        if (type === 'living') {
            if (address) {
                this.setState({
                    person: {
                        ...this.state.person,
                        livingAddress: {
                            // e.country
                            // e.city
                            // e.federal_district
                            postIndex: address.postal_code,
                            street: address.street_with_type,
                            houseNumber: address.house,
                            building: address.block,
                            apartmentNumber: address.flat,
                            fiasId: address.fias_id,
                            gpsLat: address.geo_lat,
                            gpsLon: address.geo_lon,
                        }
                    }
                });
            } else {
                this.setState({
                    person: {
                        ...this.state.person,
                        livingAddress: {
                            country: null,
                            city: null,
                            federal_district: null,
                            postIndex: null,
                            street: null,
                            houseNumber: null,
                            building: null,
                            apartmentNumber: null,
                            fiasId: null,
                            gpsLat: null,
                            gpsLon: null,
                        }
                    }
                });
            }
        }
        if (type === 'institution') {
            if (address) {
                this.setState({
                    person: {
                        ...this.state.person,
                        education: {
                            ...this.state.person.education,
                            institutionAddress: {
                                // e.country
                                // e.city
                                // e.federal_district
                                postIndex: address.postal_code,
                                street: address.street_with_type,
                                houseNumber: address.house,
                                building: address.block,
                                apartmentNumber: address.flat,
                                fiasId: address.fias_id,
                                gpsLat: address.geo_lat,
                                gpsLon: address.geo_lon,
                            }
                        }
                    }
                });
            } else {
                this.setState({
                    person: {
                        ...this.state.person,
                        education: {
                            ...this.state.person.education,
                            institutionAddress: {
                                // e.country
                                // e.city
                                // e.federal_district
                                postIndex: null,
                                street: null,
                                houseNumber: null,
                                building: null,
                                apartmentNumber: null,
                                fiasId: null,
                                gpsLat: null,
                                gpsLon: null,
                            }
                        }
                    }
                });
            }
        }
    };
    createApplicant = (e) => {
        e.preventDefault();

        if (this.state.personEditBlock1 || this.state.personEditBlock2 || this.state.personEditBlock3) {
            toast.error("Не сохраненны изменения в разеделе 'Персональные данные'");
            return
        }
        if (this.state.personEditBlock4) {
            toast.error("Не сохраненны изменения в разеделе 'Информация об образовании'");
            return
        }
        if (this.state.personEditBlock4) {
            toast.error("Не сохраненны изменения в разеделе 'Информация об образовании'");
            return
        }
        if (!this.state.applicant.familiarWithLicense) {
            this.setState({
                famError1: true
            });
            return;
        }
        if (!this.state.applicant.familiarWithCertOfAccreditation) {
            this.setState({
                famError2: true
            });
            return;
        }
        if (!this.state.applicant.personalDataProcessingConsent) {
            this.setState({
                famError3: true
            });
            return;
        }
        if (!this.state.applicant.firstTime) {
            this.setState({
                famError4: true
            });
            return;
        }
        if (!this.state.applicant.familiarWithCharter) {
            this.setState({
                famError5: true
            });
            return;
        }
        if (!this.state.applicant.familiarWithDeadlineOfOriginals) {
            this.setState({
                famError6: true
            });
            return;
        }

        let applicant = Object.assign({}, this.state.applicant);
        applicant.person = {
            id: this.props.user.person.id
        };
        applicant.intake = {
            id: this.state.intake.id
        };
        let currentDate = new Date();
        const {dispatch} = this.props;
        this.setState({
            applicantLoading: true
        });
        dispatch(createApplicant(applicant))
            .then(() => {
                this.setState({
                    applicantLoading: false
                });
                toast.success("Заявление успешно подано");
                this.props.history.push('/admission/applications');
            })
            .catch((error) => {
                this.setState({
                    applicantLoading: false
                });
            })
    };
    changeMark = (mark, id) => {
        let marks = Object.assign([], this.state.marks);
        let index = this.state.marks.findIndex(m => m.schoolSubject.id === id);
        if (index !== -1) {
            if (mark.toString() === '2' || mark.toString() === '3' || mark.toString() === '4' || mark.toString() === '5' || mark.toString() === "") {
                marks[index].grade = parseInt(mark);
                this.setState({
                    marks: marks
                })
            }
        }
    };
    saveMarks = async () => {
        let marksToCreate = this.state.marks.filter(m => !m.id && m.grade && m.grade.toString().length);
        let marksToDelete = this.state.marks.filter(m => m.id && (!m.grade || m.grade.toString().length === 0));
        let marksToUpdate =[];
        for (const m of this.state.marks) {
            if (m.id && m.grade && m.grade.toString().length > 0) {
                let propMark = this.props.user.marks.find(pm => pm.id === m.id);
                if (propMark && propMark.grade && propMark.grade.toString() !== m.grade.toString()) {
                    marksToUpdate.push(m)
                }
            }
        }

        const {dispatch} = this.props;
        this.setState({
            subjectMarksLoading: true
        });
        if (marksToCreate.length) {
            try {
                await dispatch(createUserMarks(marksToCreate));
            } catch (e) {

            }
        }
        if (marksToDelete.length) {
            let idsToDelete = [];
            marksToDelete.forEach(m => {
                idsToDelete.push(m.id)
            });
            try {
                await dispatch(deleteUserMarks(idsToDelete));
            } catch (e) {

            }
        }
        if (marksToUpdate.length) {
            try {
                await dispatch(updateUserMarks(marksToUpdate));
            } catch (e) {

            }
        }
        try {
            await dispatch(getUserMarks(this.props.user.person.id));
        } catch (e) {

        }
        this.setState({
            subjectMarksLoading: false,
            subjectMarksEdit: false,
        });
        this.processUserMarks()
    };

    render() {
        const access = this.props.access;
        let personBlock1 = this.state.personEditBlock1 ? this.state.person : this.props.user.person;
        let personBlock2 = this.state.personEditBlock2 ? this.state.person : this.props.user.person;
        let personBlock3 = this.state.personEditBlock3 ? this.state.person : this.props.user.person;
        let personBlock4 = this.state.personEditBlock4 ? this.state.person : this.props.user.person;
        let licence = this.state.collegeDocuments.find(d => d.type && d.image && d.type.name === 'Лицензии на осуществление образовательной деятельности по образовательным программам, по которым идет набор на обучение');
        let accreditation = this.state.collegeDocuments.find(d => d.type && d.image && d.type.name === 'Свидетельство о государственной аккредитации');
        let articles = this.state.collegeDocuments.find(d => d.type && d.image && d.type.name === 'Устав');

        if (!access.isLoggedIn) {
            return <Redirect to="/login"/>;
        }

        return (
            !this.state.intakeLoading ?
                <div className="container-fluid mt-5 request">
                    <div className={"header"}>
                        <h1>Заявление на поступление в образовательную организацию</h1>
                    </div>
                    { /*----------------ОБРАЗОВАТЕЛЬНАЯ ОРГАНИЗАЦИИ И ПРОГРАММА------------------*/}
                    <div className="section">
                        <h5 className="mb-4">
                            <span className="number">1.</span> Выбранная образовательная организация и образовательная
                            программа (профессия, специальность)
                        </h5>
                        <div className="college-container mb-4">
                            <h5>{this.state.intake.specialty.college.fullName}</h5>
                            {this.state.intake.specialty.college.address && this.state.intake.specialty.college.address.fullAddress && this.state.intake.specialty.college.address.fullAddress.length &&
                            <p className="text-secondary fw-light">{this.state.intake.specialty.college.address.fullAddress}</p>
                            }
                            <div className="intake-container">
                                <div className="d-flex justify-content-between align-items-start mb-3 flex-wrap">
                                    <p className="mb-1 mt-1">
                                        <span
                                            className="fw-lighter">{this.state.intake.specialty.specialty.code}</span> - {this.state.intake.specialty._instanceName}
                                        {this.state.intake.specialtyBranch && this.state.intake.specialtyBranch.length &&
                                        " (" + this.state.intake.specialtyBranch + ")"
                                        }
                                    </p>
                                    <p className="mb-1 mt-1">
                                        {this.state.intake.budgetType === "BT_BUDGET" && "Бюджетный"}
                                        {this.state.intake.budgetType === "BT_COMMERCIAL" && "Платный"}
                                        , мест: {this.state.intake.numberOfVacancies}
                                    </p>
                                </div>
                                <div className="d-flex justify-content-between flex-wrap align-items-start mb-3">
                                    <p className="mb-0 text-secondary fw-light">
                                        Учебный год: {this.state.intake.year}
                                        {this.state.intake.specialty.studyForm && this.state.intake.specialty.studyForm === "SF_FULLTIME" && ", очная форма обучения"}
                                        {this.state.intake.specialty.studyForm && this.state.intake.specialty.studyForm === "SF_CORRESPONDENCE" && ", очно-заочная форма обучения"}
                                        {this.state.intake.specialty.studyForm && this.state.intake.specialty.studyForm === "SF_EVENING" && ", заочная форма обучения"}
                                        {this.state.intake.studyLength && ", продолжительность обучения: " + this.state.intake.studyLength}
                                    </p>
                                </div>
                                {this.state.intakeDetailsLoading ?
                                    <div className="d-flex justify-content-between align-items-start mb-3">
                                        <img
                                            src={process.env.PUBLIC_URL + '/resources/images/box-preloader.svg'}
                                            height="10"
                                        />
                                    </div>
                                    :
                                    <div className="d-flex flex-wrap">
                                        {this.state.intake.tests && this.state.intake.tests.length > 0 &&
                                        <div className="details">
                                            <FontAwesomeIcon icon={faPersonChalkboard} className="me-2"
                                                             color="#0094ff"/> Вступительные
                                            испытания <Badge>{this.state.intake.tests.length}</Badge>
                                        </div>
                                        }
                                        {this.state.intake.specialty.dormitoryProvided &&
                                        <div className="details"><FontAwesomeIcon icon={faBuilding} className="me-2"
                                                                                  color="#0094ff"/> Предоставляется
                                            общежитие</div>
                                        }
                                        {this.state.intake.specialty.medCertRequired &&
                                        <div className="details"><FontAwesomeIcon icon={faFileContract} className="me-2"
                                                                                  color="#0094ff"/> Справка о состоянии
                                            здоровья</div>
                                        }
                                    </div>
                                }
                                {this.state.intake.intakePeriodStart && this.state.intake.intakePeriodEnd &&
                                <div className="d-flex justify-content-end align-items-center fw-light mt-2">
                                    <FontAwesomeIcon icon={faCalendar} className={'me-2'} color="#0094ff"/>
                                    <p className="mb-0 text-secondary">
                                        Период набора:
                                        c {new Date(this.state.intake.intakePeriodStart).toLocaleDateString()} по {new Date(this.state.intake.intakePeriodEnd).toLocaleDateString()}
                                    </p>
                                </div>
                                }
                            </div>
                        </div>
                        {this.state.intake.specialty.dormitoryProvided &&
                        <Form.Group className="mb-3 form-check">
                            <input value={this.state.applicant.dormitory}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               dormitory: e.target.checked
                                           }
                                       })
                                   }}
                                   type="checkbox"
                                   id="dormitory"
                                   name="dormitory"
                                   className="form-check-input"/>
                            <label title="" htmlFor="dormitory" className="form-check-label">Нуждаюсь в
                                общежитии</label>
                        </Form.Group>
                        }
                        <Form.Group className="mb-3 form-check">
                            <input value={this.state.applicant.underContract}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               underContract: e.target.checked
                                           }
                                       })
                                   }}
                                   type="checkbox"
                                   id="underContract"
                                   name="underContract"
                                   className="form-check-input"/>
                            <label title="" htmlFor="underContract" className="form-check-label">Есть договор о целевом
                                обучении</label>
                        </Form.Group>
                        {this.state.applicant.underContract && this.renderDocumentList(0)}
                        <Form.Group className="mb-3 form-check">
                            <input value={this.state.applicant.entitlement}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               entitlement: e.target.checked
                                           }
                                       })
                                   }}
                                   type="checkbox"
                                   id="entitlement"
                                   name="entitlement"
                                   className="form-check-input"/>
                            <label title="" htmlFor="entitlement" className="form-check-label">Необходимы особые условия
                                поступления (ограниченные возможности или инвалидность)</label>
                        </Form.Group>
                        {this.state.applicant.entitlement && this.renderDocumentList(1)}
                    </div>
                    { /*----------------МЕД СПРАВКА ДАННЫЕ------------------*/}
                    {this.state.intake.specialty.medCertRequired &&
                    <div className="section mt-5">
                        <h5 className="mb-4">
                            <span className="number">2.</span> Медицинская справка по форме 086/y
                        </h5>
                        {this.renderDocumentList(2)}
                    </div>
                    }
                    <div className="section mt-5">
                        <h5 className="mb-4">
                            <span
                                className="number">{this.state.intake.specialty.medCertRequired ? 3 : 2}.</span> Индивидуальные
                            достижения
                        </h5>
                        <p className="text-secondary">При поступлении могут учитываться индивидуальные достижения:
                            аттестат об основном общем образовании с отличием, спортивные успехи, победы в олимпиадах и
                            прочее. ЧТобы ваши достижения были точно учтены при приеме, загрузите сканы или фотографии
                            подтверждающих документов (дипломы, сертификаты и прочее)</p>
                        {this.renderDocumentList(3)}
                    </div>
                    { /*----------------ПЕРСОНАЛЬНЫЕ ДАННЫЕ------------------*/}
                    <div className="section mt-5">
                        <h5 className="mb-4">
                            <span className="number">
                                {this.state.intake.specialty.medCertRequired ? 4 : 3}.
                            </span> Персональные данные
                        </h5>
                        <p className="text-secondary mb-4">Персональные данные будут указаны в заявлении, поэтому перед
                            отправкой обязательно проверьте их. Если вы нашли ошибки, перед отправкой заявления
                            исправьте ваши данные. Ваши исправления отразятся во всех ранее поданных заявлениях</p>
                        <div className={"mb-2 mb-5 person-block"}>
                            <p className="d-flex align-items-center flex-wrap fw-bolder">
                                <span
                                    className="me-3">{this.state.personEditBlock1 ? "Редактирование личных данных" : personBlock1.lastName + " " + personBlock1.firstName + " " + personBlock1.middleName}</span>
                                {this.state.personEditBlock1 ?
                                    <span>
                                        <Button variant={"link"}
                                                size={"sm"}
                                                onClick={() => {
                                                    this.setState({
                                                        personEditBlock1: false,
                                                        person: this.props.user.person
                                                    })
                                                }}
                                                className="fw-bold text-danger">
                                            <FontAwesomeIcon icon={faTimes}/> Отменить
                                        </Button>
                                        <Button variant={"link"}
                                                size={"sm"}
                                                className="fw-bold"
                                                onClick={() => this.updateUserPerson(1)}>
                                            {this.state.personLoadingBlock1 ?
                                                <Spinner animation="border" size="sm" variant="primary"/> :
                                                <FontAwesomeIcon icon={faCheck}/>} Сохранить
                                        </Button>
                                    </span>
                                    :
                                    <Button variant={"link"}
                                            size={"sm"}
                                            onClick={() => {
                                                this.setState({personEditBlock1: true})
                                            }}
                                            disabled={this.state.personEditBlock2 || this.state.personEditBlock3 || this.state.personEditBlock4}
                                            className="fw-bold">
                                        <FontAwesomeIcon icon={faPencil}/> Редактировать
                                    </Button>
                                }
                            </p>
                            <Row>
                                {this.state.personEditBlock1 &&
                                <>
                                    <Col xs={12} sm={12} md={4}>
                                        <Form.Group className="mb-3 required">
                                            <Form.Label>Фамилия</Form.Label>
                                            <input
                                                className={this.state.errorLastName ? "is-invalid form-control" : "form-control"}
                                                placeholder={"Не указана"}
                                                value={personBlock1.lastName ? personBlock1.lastName : ""}
                                                onChange={(e) => this.setState({
                                                    person: {...this.state.person, lastName: e.target.value}
                                                })}
                                                disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                                type="text"/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <Form.Group className="mb-3 required">
                                            <Form.Label>Имя</Form.Label>
                                            <input
                                                className={this.state.errorFirstName ? "is-invalid form-control" : "form-control"}
                                                placeholder={"Не указано"}
                                                value={personBlock1.firstName ? personBlock1.firstName : ""}
                                                onChange={(e) => this.setState({
                                                    person: {...this.state.person, firstName: e.target.value}
                                                })}
                                                disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                                type="text"/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Отчество</Form.Label>
                                            <input className={"form-control"}
                                                   placeholder={"Не указано"}
                                                   value={personBlock1.middleName ? personBlock1.middleName : ""}
                                                   onChange={(e) => this.setState({
                                                       person: {...this.state.person, middleName: e.target.value}
                                                   })}
                                                   disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                                   type="text"/>
                                        </Form.Group>
                                    </Col>
                                </>
                                }
                                <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Дата рождения</Form.Label>
                                        <Datepicker
                                            className={"form-control"}
                                            selected={personBlock1.birthDate ? new Date(personBlock1.birthDate) : null}
                                            dateFormat="dd.MM.yyyy"
                                            locale={'ru'}
                                            showMonthDropdown
                                            placeholder={"Не указана"}
                                            showYearDropdown
                                            maxDate={new Date()}
                                            dropdownMode="select"
                                            disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                            onChange={(date) => {
                                                this.setState({
                                                    person: {
                                                        ...this.state.person,
                                                        birthDate: new Date(date).toISOString().split("T")[0]
                                                    }
                                                })
                                            }}
                                            required/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <Form.Group className="mb-3 required">
                                        <Form.Label>Пол</Form.Label>
                                        <select
                                            className={this.state.errorSex ? "is-invalid form-control" : "form-control"}
                                            placeholder={"Не указан"}
                                            value={personBlock1.sex}
                                            onChange={(e) => {
                                                this.setState({
                                                    person: {
                                                        ...this.state.person, sex: e.target.value
                                                    }
                                                })
                                            }}
                                            disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                        >
                                            <option value="SEX_MAN">Мужской</option>
                                            <option value="SEX_WOMAN">Женский</option>
                                        </select>

                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>СНИЛС</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указан"}
                                               value={personBlock1.codeSNILS ? personBlock1.codeSNILS : ""}
                                               onChange={(e) => {
                                                   this.setState({
                                                       person: {
                                                           ...this.state.person,
                                                           codeSNILS: e.target.value
                                                       }
                                                   })
                                               }}
                                               disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ИНН</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указан"}
                                               value={personBlock1.codeINN ? personBlock1.codeINN : ""}
                                               onChange={(e) => {
                                                   this.setState({
                                                       person: {
                                                           ...this.state.person,
                                                           codeINN: e.target.value
                                                       }
                                                   })
                                               }}
                                               disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Место рождения</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указано"}
                                               value={personBlock1.birthPlace ? personBlock1.birthPlace : ""}
                                               onChange={(e) => {
                                                   this.setState({
                                                       person: {
                                                           ...this.state.person,
                                                           birthPlace: e.target.value
                                                       }
                                                   })
                                               }}
                                               disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Гражданство</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указано"}
                                               value={personBlock1.citizenship ? personBlock1.citizenship : ""}
                                               onChange={(e) => {
                                                   this.setState({
                                                       person: {
                                                           ...this.state.person,
                                                           citizenship: e.target.value
                                                       }
                                                   })
                                               }}
                                               disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Родной язык</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указан"}
                                               value={personBlock1.nativeLanguage ? personBlock1.nativeLanguage : ""}
                                               onChange={(e) => {
                                                   this.setState({
                                                       person: {
                                                           ...this.state.person,
                                                           nativeLanguage: e.target.value
                                                       }
                                                   })
                                               }}
                                               disabled={!this.state.personEditBlock1 || this.state.personLoadingBlock1}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className={"mb-1  mt-2 person-block"}>
                            <p className="d-flex align-items-center fw-bolder flex-wrap">
                                <span className="me-3">Документы удостоверяющие личность и гражданство</span>
                                {this.state.personEditBlock2 ?
                                    <span>
                                        <Button variant={"link"}
                                                size="sm"
                                                onClick={() => {
                                                    this.setState({
                                                        personEditBlock2: false,
                                                        person: this.props.user.person
                                                    })
                                                }}
                                                className="fw-bold text-danger">
                                            <FontAwesomeIcon icon={faTimes}/> Отменить
                                        </Button>
                                        <Button variant={"link"}
                                                size="sm"
                                                className="fw-bold"
                                                onClick={() => this.updateUserPerson(2)}>
                                            {this.state.personLoadingBlock2 ?
                                                <Spinner animation="border" size="sm" variant="primary"/> :
                                                <FontAwesomeIcon icon={faCheck}/>} Сохранить
                                        </Button>
                                    </span>
                                    :
                                    <Button variant={"link"}
                                            size="sm"
                                            onClick={() => {
                                                this.setState({personEditBlock2: true})
                                            }}
                                            disabled={this.state.personEditBlock1 || this.state.personEditBlock3 || this.state.personEditBlock4}
                                            className="fw-bold">
                                        <FontAwesomeIcon icon={faPencil}/> Редактировать
                                    </Button>
                                }
                            </p>
                            <Row>
                                <Col xs={12} sm={3} md={3} lg={3} xl={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Серия</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указан"}
                                               value={personBlock2.passport.serie ? personBlock2.passport.serie : ""}
                                               onChange={(e) => this.setState({
                                                   person: {
                                                       ...this.state.person,
                                                       passport: {
                                                           ...this.state.person.passport,
                                                           serie: e.target.value
                                                       }
                                                   }
                                               })}
                                               disabled={!this.state.personEditBlock2 || this.state.personLoadingBlock2}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={5} md={6} lg={4} xl={4}>
                                    <Form.Group className="mb-3 required">
                                        <Form.Label>Номер</Form.Label>
                                        <input
                                            className={this.state.errorPassportNumber ? "is-invalid form-control" : "form-control"}
                                            placeholder={"Не указана"}
                                            value={personBlock2.passport.number ? personBlock2.passport.number : ""}
                                            onChange={(e) => this.setState({
                                                person: {
                                                    ...this.state.person,
                                                    passport: {
                                                        ...this.state.person.passport,
                                                        number: e.target.value
                                                    }
                                                }
                                            })}
                                            disabled={!this.state.personEditBlock2 || this.state.personLoadingBlock2}
                                            type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4} md={3} lg={3} xl={2}>
                                    <Form.Group className="mb-3 required">
                                        <Form.Label>Дата выдачи</Form.Label>
                                        <Datepicker
                                            className={this.state.errorPassportDate ? "is-invalid form-control" : "form-control"}
                                            selected={personBlock2.passport.date ? new Date(personBlock2.passport.date) : null}
                                            dateFormat="dd.MM.yyyy"
                                            locale={'ru'}
                                            showMonthDropdown
                                            placeholder={"Не указана"}
                                            showYearDropdown
                                            maxDate={new Date()}
                                            dropdownMode="select"
                                            disabled={!this.state.personEditBlock2 || this.state.personLoadingBlock2}
                                            onChange={(date) => {
                                                this.setState({
                                                    person: {
                                                        ...this.state.person,
                                                        passport: {
                                                            ...this.state.person.passport,
                                                            date: new Date(date).toISOString().split("T")[0]
                                                        }
                                                    }
                                                })
                                            }}
                                            required/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={4} md={4} lg={3} xl={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Код подразделения</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указана"}
                                               value={personBlock2.passport.depCode ? personBlock2.passport.depCode : ""}
                                               onChange={(e) => this.setState({
                                                   person: {
                                                       ...this.state.person,
                                                       passport: {
                                                           ...this.state.person.passport,
                                                           depCode: e.target.value
                                                       }
                                                   }
                                               })}
                                               disabled={!this.state.personEditBlock2 || this.state.personLoadingBlock2}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={8} md={8} lg={7} xl={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Кем выдан</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указана"}
                                               value={personBlock2.passport.issuer ? personBlock2.passport.issuer : ""}
                                               onChange={(e) => this.setState({
                                                   person: {
                                                       ...this.state.person,
                                                       passport: {
                                                           ...this.state.person.passport,
                                                           issuer: e.target.value
                                                       }
                                                   }
                                               })}
                                               disabled={!this.state.personEditBlock2 || this.state.personLoadingBlock2}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        {this.renderDocumentList(4)}
                        <div className={"mb-5  mt-2 person-block"}>
                            <p className="d-flex align-items-center fw-bolder flex-wrap">
                                <span className="me-3">Контактная информация</span>
                                {this.state.personEditBlock3 ?
                                    <span>
                                        <Button variant={"link"}
                                                onClick={() => {
                                                    this.setState({
                                                        personEditBlock3: false,
                                                        person: this.props.user.person
                                                    });
                                                    this.checkAddressesEquals();
                                                }}
                                                size={"sm"}
                                                className="fw-boldtext-danger">
                                            <FontAwesomeIcon icon={faTimes}/> Отменить
                                        </Button>
                                        <Button variant={"link"}
                                                className="fw-bold"
                                                size="sm"
                                                onClick={() => this.updateUserPerson(3)}>
                                            {this.state.personLoadingBlock3 ?
                                                <Spinner animation="border" size="sm" variant="primary"/> :
                                                <FontAwesomeIcon icon={faCheck}/>} Сохранить
                                        </Button>
                                    </span>
                                    :
                                    <Button variant={"link"}
                                            onClick={() => {
                                                this.setState({personEditBlock3: true})
                                            }}
                                            size="sm"
                                            disabled={this.state.personEditBlock1 || this.state.personEditBlock2 || this.state.personEditBlock4}
                                            className="fw-bold">
                                        <FontAwesomeIcon icon={faPencil}/> Редактировать
                                    </Button>
                                }
                            </p>
                            <Row>
                                <Col xs={12} sm={12} md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Номер телефона</Form.Label>
                                        <input className={"form-control"}
                                               placeholder={"Не указан"}
                                               value={personBlock3.phoneNumber ? personBlock3.phoneNumber : ""}
                                               onChange={(e) => this.setState({
                                                   person: {...this.state.person, phoneNumber: e.target.value}
                                               })}
                                               disabled={!this.state.personEditBlock3 || this.state.personLoadingBlock3}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={7}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Адрес регистрации</Form.Label>
                                        {this.state.personEditBlock3 ?
                                            <AddressSuggestions token="aadd7f8fdf45345a6d4c038b1002284b90868ca7"
                                                                defaultQuery={this.getAddress(this.state.person.regAddress)}
                                                                selectOnBlur={true}
                                                                onChange={(e) => {
                                                                    this.setAddress(e.data, 'reg')
                                                                }}/>
                                            :
                                            <input className={"form-control"}
                                                   placeholder={"Не указан"}
                                                   value={this.getAddress(this.state.person.regAddress) ? this.getAddress(this.state.person.regAddress) : ""}
                                                   disabled={true}
                                                   type="text"/>
                                        }
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={7}>
                                    <Form.Group className="mb-3">
                                        <input onChange={(e) => {
                                            this.setState({
                                                addressesEquals: e.target.checked
                                            })
                                        }}
                                               checked={this.state.addressesEquals}
                                               type="checkbox"
                                               id="addressesEquals"
                                               disabled={!this.state.personEditBlock3 || this.state.personLoadingBlock3}
                                               name="addressesEquals"
                                               className="form-check-input"/>
                                        <label title="" htmlFor="addressesEquals" className="form-check-label">
                                            Адрес проживания совпадает с адресом регистрации
                                        </label>
                                    </Form.Group>
                                </Col>
                                {!this.state.addressesEquals &&
                                <Col xs={12} sm={12} md={8} lg={7}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Адрес проживания</Form.Label>
                                        {!this.state.personEditBlock3 ?
                                            <input className={"form-control"}
                                                   placeholder={"Не указан"}
                                                   value={this.getAddress(this.state.person.livingAddress) ? this.getAddress(this.state.person.livingAddress) : ""}
                                                   disabled={true}
                                                   type="text"/>
                                            :
                                            <AddressSuggestions token="aadd7f8fdf45345a6d4c038b1002284b90868ca7"
                                                                defaultQuery={this.getAddress(this.state.person.livingAddress)}
                                                                selectOnBlur={true}
                                                                onChange={(e) => {
                                                                    this.setAddress(e.data, 'living')
                                                                }}/>
                                        }
                                    </Form.Group>
                                </Col>
                                }
                            </Row>
                        </div>
                    </div>
                    { /*----------------ИНФОРМАЦИЯ ОБ ОБРАЗОВАНИИ------------------*/}
                    <div className="section mt-5">
                        <h5 className="mb-4">
                            <span className="number">
                                {this.state.intake.specialty.medCertRequired ? 5 : 4}.
                            </span> Информация об образовании
                        </h5>
                        <p className="text-secondary  mb-4">Информация об образовании, оценки из аттестата и копии
                            подтверждающих документов будут добавлены в ваш профиль и отобразятся в заявлении. Перед
                            отправкой обязательно заполните и проверьте всю информацию. Если вы нашли ошибки, перед
                            отправкой заявления исправьте ваши данные. Ваши исправления отразятся во всех ранее поданных
                            заявлениях</p>
                        <div className={"mb-2 mb-3 person-block"}>
                            <p className="d-flex align-items-center fw-bolder">
                                Данные о полученном образовании и образовательном учреждении
                                {this.state.personEditBlock4 ?
                                    <span>
                                        <Button variant={"link"}
                                                size={"sm"}
                                                onClick={() => {
                                                    this.setState({
                                                        personEditBlock4: false,
                                                        person: this.props.user.person
                                                    })
                                                }}
                                                className="fw-bold ms-3 text-danger">
                                            <FontAwesomeIcon icon={faTimes}/> Отменить
                                        </Button>
                                        <Button variant={"link"}
                                                size={"sm"}
                                                className="fw-bold"
                                                onClick={() => this.updateUserPerson(4)}>
                                            {this.state.personLoadingBlock4 ?
                                                <Spinner animation="border" size="sm" variant="primary"/> :
                                                <FontAwesomeIcon icon={faCheck}/>} Сохранить
                                        </Button>
                                    </span>
                                    :
                                    <Button variant={"link"}
                                            size={"sm"}
                                            onClick={() => {
                                                this.setState({personEditBlock4: true})
                                            }}
                                            disabled={this.state.personEditBlock2 || this.state.personEditBlock3 || this.state.personEditBlock1}
                                            className="fw-bold ms-3">
                                        <FontAwesomeIcon icon={faPencil}/> Редактировать
                                    </Button>
                                }
                            </p>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Уровень образования</Form.Label>
                                        <select className={"form-control"}
                                                placeholder="Не указан"
                                                value={personBlock4.education.level ? personBlock4.education.level : ""}
                                                disabled={!this.state.personEditBlock4 || this.state.personLoadingBlock4}
                                                onChange={(e) => {
                                                    this.setState({
                                                        person: {
                                                            ...this.state.person,
                                                            education: {
                                                                ...this.state.person.education,
                                                                level: e.target.value && e.target.value.length ? e.target.value : null
                                                            }
                                                        }
                                                    })
                                                }}>
                                            <option></option>
                                            <option value="EL_BASIC">Основное общее (9 классов)</option>
                                            <option value="EL_SECONDARY">Среднее (полное) общее (11 классов)</option>
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={3} md={3} lg={3} xl={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Средний балл аттестата</Form.Label>
                                        <InputMask
                                            className={"form-control"}
                                            mask="9.99"
                                            maskChar={'_'}
                                            placeholder={"Не указан"}
                                            value={personBlock4.avgCertGrade ? personBlock4.avgCertGrade : ""}
                                            onChange={(e) => this.setState({
                                                person: {...this.state.person, avgCertGrade: e.target.value.length? e.target.value : null}
                                            })}
                                            disabled={!this.state.personEditBlock4 || this.state.personLoadingBlock4}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={3} md={3} lg={3} xl={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Год выпуска</Form.Label>
                                        <InputMask mask="9999"
                                                   className={"form-control"}
                                                   maskChar={'_'}
                                                   alwaysShowMask={true}
                                                   disabled={!this.state.personEditBlock4 || this.state.personLoadingBlock4}
                                                   value={personBlock4.education.graduationYear ? personBlock4.education.graduationYear : ""}
                                                   onChange={(e) => this.setState({
                                                       person: {
                                                           ...this.state.person,
                                                           education: {
                                                               ...this.state.person.education,
                                                               graduationYear: e.target.value && e.target.value.length ? e.target.value : null
                                                           }
                                                       }
                                                   })}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={8}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Окончено учебное заведение</Form.Label>
                                        <input className={"form-control"}
                                               disabled={!this.state.personEditBlock4 || this.state.personLoadingBlock4}
                                               value={personBlock4.education.graduatedFrom ? personBlock4.education.graduatedFrom : ""}
                                               onChange={(e) => this.setState({
                                                   person: {
                                                       ...this.state.person,
                                                       education: {
                                                           ...this.state.person.education,
                                                           graduatedFrom: e.target.value && e.target.value.length ? e.target.value : null
                                                       }
                                                   }
                                               })}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={6} sm={4} md={3} lg={2} xl={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Номер группы/класса</Form.Label>
                                        <input className={"form-control"}
                                               disabled={!this.state.personEditBlock4 || this.state.personLoadingBlock4}
                                               value={personBlock4.education.classNumber ? personBlock4.education.classNumber : ""}
                                               onChange={(e) => this.setState({
                                                   person: {
                                                       ...this.state.person,
                                                       education: {
                                                           ...this.state.person.education,
                                                           classNumber: e.target.value && e.target.value.length ? e.target.value : null
                                                       }
                                                   }
                                               })}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} md={9} lg={10} xl={10}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Иностранные языки</Form.Label>
                                        <input className={"form-control"}
                                               disabled={!this.state.personEditBlock4 || this.state.personLoadingBlock4}
                                               value={personBlock4.education.foreignLanguages ? personBlock4.education.foreignLanguages : ""}
                                               onChange={(e) => this.setState({
                                                   person: {
                                                       ...this.state.person,
                                                       education: {
                                                           ...this.state.person.education,
                                                           foreignLanguages: e.target.value && e.target.value.length ? e.target.value : null
                                                       }
                                                   }
                                               })}
                                               type="text"/>
                                    </Form.Group>
                                </Col>
                                {/*<Col xs={12} sm={12} md={8} lg={7}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Адрес регистрации</Form.Label>
                                        {this.state.personEditBlock4 ?
                                            <AddressSuggestions token="aadd7f8fdf45345a6d4c038b1002284b90868ca7"
                                                                defaultQuery={this.getAddress(this.state.person.education.institutionAddress)}
                                                                selectOnBlur={true}
                                                                onChange={(e) => {
                                                                    this.setAddress(e.data, 'institution')
                                                                }}/>
                                            :
                                            <input className={"form-control"}
                                                   placeholder={"Не указан"}
                                                   value={this.getAddress(this.state.person.education.institutionAddress) ? this.getAddress(this.state.person.education.institutionAddress) : ""}
                                                   disabled={true}
                                                   type="text"/>
                                        }
                                    </Form.Group>
                                </Col>*/}
                            </Row>
                        </div>
                        {this.renderDocumentList(5)}
                        <div className={"mb-2 mb-5 person-block"}>
                            <p className="d-flex align-items-center fw-bolder">
                                Оценки в аттестате
                                {this.state.subjectMarksEdit ?
                                    <span>
                                        <Button variant={"link"}
                                                size={"sm"}
                                                onClick={() => {
                                                    this.setState({
                                                        subjectMarksEdit: false
                                                    });
                                                    this.processUserMarks();
                                                }}
                                                className="fw-bold ms-3 text-danger">
                                            <FontAwesomeIcon icon={faTimes}/> Отменить
                                        </Button>
                                        <Button variant={"link"}
                                                size={"sm"}
                                                className="fw-bold"
                                                onClick={() => this.saveMarks()}>
                                            {this.state.subjectMarksLoading ?
                                                <Spinner animation="border" size="sm" variant="primary"/> :
                                                <FontAwesomeIcon icon={faCheck}/>} Сохранить
                                        </Button>
                                    </span>
                                    :
                                    <Button variant={"link"}
                                            size={"sm"}
                                            onClick={() => {
                                                this.setState({subjectMarksEdit: true})
                                            }}
                                            className="fw-bold ms-3">
                                        <FontAwesomeIcon icon={faPencil}/> Редактировать
                                    </Button>
                                }
                            </p>
                            {
                                this.state.marks.map((m, index) => {
                                    return (
                                        <div key={index}
                                             className="school-subject d-flex justify-content-between align-items-center">
                                            <span>{m.schoolSubject.name}</span>
                                            <input className="grade form-control"
                                                   onChange={(e) => {
                                                       this.changeMark(e.target.value, m.schoolSubject.id)
                                                   }}
                                                   disabled={!this.state.subjectMarksEdit || this.state.subjectMarksLoading}
                                                   value={m.grade ? m.grade : ""}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    { /*----------------СОГЛАСИЯ------------------*/}
                    <div className="section mt-5">
                        <Form.Group className="mb-4 form-check">
                            <input value={this.state.applicant.familiarWithLicense}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               familiarWithLicense: e.target.checked
                                           },
                                           famError1: false
                                       })
                                   }}
                                   type="checkbox"
                                   id="familiarWithLicense"
                                   name="familiarWithLicense"
                                   className={this.state.famError1 ? "is-invalid form-check-input":"form-check-input"}/>
                            {licence ?
                                <label title="" htmlFor="familiarWithLicense" className="form-check-label">
                                    Ознакомлен с <a
                                    href={serverUrl + "/rest/files?access_token=" + TokenService.getLocalAccessToken() + "&fileRef=" + licence.image + "&attachment=true"}>лицензией
                                    на осуществление образовательной деятельности</a>
                                </label>
                                :
                                <label title="" htmlFor="familiarWithLicense" className="form-check-label">Ознакомлен с
                                    лицензией на осуществление образовательной деятельности</label>
                            }
                            <div className="invalid-tooltip">Обязательно для подачи заявления</div>
                        </Form.Group>
                        <Form.Group className="mb-4 form-check">
                            <input value={this.state.applicant.familiarWithCertOfAccreditation}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               familiarWithCertOfAccreditation: e.target.checked
                                           },
                                           famError2: false
                                       })
                                   }}
                                   type="checkbox"
                                   id="familiarWithCertOfAccreditation"
                                   name="familiarWithCertOfAccreditation"
                                   className={this.state.famError2 ? "is-invalid form-check-input":"form-check-input"}/>
                            {accreditation ?
                                <label title="" htmlFor="familiarWithCertOfAccreditation" className="form-check-label">
                                    Ознакомлен со <a
                                    href={serverUrl + "/rest/files?access_token=" + TokenService.getLocalAccessToken() + "&fileRef=" + accreditation.image + "&attachment=true"}>свидетельством
                                    о государственной аккредитации образовательной деятельности по образовательным
                                    программам и приложениям к ним</a>
                                </label>
                                :
                                <label title="" htmlFor="familiarWithCertOfAccreditation" className="form-check-label">Ознакомлен
                                    со свидетельством о государственной аккредитации образовательной деятельности по
                                    образовательным программам и приложениям к ним</label>
                            }
                            <div className="invalid-tooltip">Обязательно для подачи заявления</div>
                        </Form.Group>
                        <Form.Group className="mb-4 form-check">
                            <input value={this.state.applicant.personalDataProcessingConsent}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               personalDataProcessingConsent: e.target.checked
                                           },
                                           famError3: false
                                       })
                                   }}
                                   type="checkbox"
                                   id="personalDataProcessingConsent"
                                   name="personalDataProcessingConsent"
                                   className={this.state.famError3 ? "is-invalid form-check-input":"form-check-input"}/>
                            <label title="" htmlFor="personalDataProcessingConsent" className="form-check-label">Согласен
                                на обработку полученных в связи с приемом в образовательную организацию персональных
                                данных поступающих</label>
                            <div className="invalid-tooltip">Обязательно для подачи заявления</div>
                        </Form.Group>
                        <Form.Group className="mb-4 form-check">
                            <input value={this.state.applicant.firstTime}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               firstTime: e.target.checked
                                           },
                                           famError4: false
                                       })
                                   }}
                                   type="checkbox"
                                   id="firstTime"
                                   name="firstTime"
                                   className={this.state.famError4 ? "is-invalid form-check-input":"form-check-input"}/>
                            <label title="" htmlFor="firstTime" className="form-check-label">Подтверждаю факт получения
                                среднего профессионального образования впервые</label>
                            <div className="invalid-tooltip">Обязательно для подачи заявления</div>
                        </Form.Group>
                        <Form.Group className="mb-4 form-check">
                            <input value={this.state.applicant.familiarWithCharter}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               familiarWithCharter: e.target.checked
                                           },
                                           famError5: false
                                       })
                                   }}
                                   type="checkbox"
                                   id="familiarWithCharter"
                                   name="familiarWithCharter"
                                   className={this.state.famError5 ? "is-invalid form-check-input":"form-check-input"}/>
                            {articles ?
                                <label title="" htmlFor="familiarWithCharter" className="form-check-label">
                                    Ознакомлен с <a
                                    href={serverUrl + "/rest/files?access_token=" + TokenService.getLocalAccessToken() + "&fileRef=" + articles.image + "&attachment=true"}>уставом
                                    образовательной организации</a>
                                </label>
                                :
                                <label title="" htmlFor="familiarWithCharter" className="form-check-label">Ознакомлен с
                                    уставом образовательной организации</label>
                            }
                            <div className="invalid-tooltip">Обязательно для подачи заявления</div>
                        </Form.Group>
                        <Form.Group className="mb-4 form-check">
                            <input value={this.state.applicant.familiarWithDeadlineOfOriginals}
                                   onChange={(e) => {
                                       this.setState({
                                           applicant: {
                                               ...this.state.applicant,
                                               familiarWithDeadlineOfOriginals: e.target.checked
                                           },
                                           famError6: false
                                       })
                                   }}
                                   type="checkbox"
                                   id="familiarWithDeadlineOfOriginals"
                                   name="familiarWithDeadlineOfOriginals"
                                   className={this.state.famError6 ? "is-invalid form-check-input":"form-check-input"}/>
                            <label title="" htmlFor="familiarWithDeadlineOfOriginals" className="form-check-label">Ознакомлен
                                с датой предоставления оригинала документа об образовании и (или) документа об
                                образовании и о квалификации</label>
                            <div className="invalid-tooltip">Обязательно для подачи заявления</div>
                        </Form.Group>
                    </div>
                    { /*----------------КНОПКИ ПОДАЧИ------------------*/}
                    <div className="section last-section mt-5 mb-5 d-flex justify-content-between">
                        <Button size={"lg"} variant={"outline-danger"}
                            onClick={() => {this.props.history.goBack()}}>
                            Отменить
                        </Button>
                        <Button size={"lg"} variant={"primary"} onClick={(e) => this.createApplicant(e)}>
                            {this.state.applicantLoading? <Spinner animation="border" variant="light" size={"sm"} /> : <FontAwesomeIcon icon={faPaperPlane}/>} Подать заявление
                        </Button>
                    </div>
                    { /*----------------МОДАЛЬНЫЕ ОКНА------------------*/}
                    <Modal aria-labelledby="contained-modal-title-vcenter"
                           centered
                           show={this.state.showDocumentModal}>
                        <Modal.Header>
                            <Modal.Title>
                                {this.state.documentModal && this.state.documentModal.new && this.state.documentModal.type &&
                                documentTypes.find(d => d.searchText === this.state.documentModal.type).name
                                }
                                {this.state.documentModal && this.state.documentModal.new && !this.state.documentModal.type && "Новый документ"}
                                {this.state.documentModal && this.state.documentModal.edit && "Редактирование документа"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.documentModal &&
                            <Document document={this.state.documentModal} dispatch={this.props.dispatch}/>
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal aria-labelledby="contained-modal-title-vcenter"
                           centered
                           show={this.state.onDeleteDocument}>
                        <Modal.Body>
                            Вы уверены, что хотите удалить документ? Документ будет удален из всех ранее поданных заявлений!
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => this.setState({onDeleteDocument: false})}>Отменить</Button>
                            <Button variant="primary" className="ms-3" onClick={() => this.deleteDocument(this.state.onDeleteDocument)}>Удалить</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                :
                <div>

                </div>

        );
    }
}

function mapStateToProps(state) {
    const access = state.auth;
    const user = state.user;
    return {
        access,
        user
    };
}

export default connect(mapStateToProps)(Request);
