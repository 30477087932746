import React, {Component} from "react";
import {Row, Col, Form, Button, Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import DocumentService from "../../services/document.service"
import Select from 'react-select'
import {faCheck, faFile, faFileExcel, faFilePdf, faFileWord, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import eventBus from "../../common/EventBus";
import {deleteUserDocument, saveNewUserDocument, updateUserDocument} from "../../actions/user";
import { toast } from 'react-toastify';
import Datepicker, {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import {serverUrl} from "../../services/api";
import TokenService from "../../services/token.service";

registerLocale('ru', ru);

class Document extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            id: null,
            image: null,
            file: null,
            docSeries: null,
            comments: null,
            docNumber: null,
            kind: null,
            docDate: null,
            type: null,
            issuer: null,
            name: null,
            loading: true,
            types: [],
            kinds: [],
            saving: false,
            nameError: false,
            imageError: false,
            docNumberError: false,
            docSeriesError: false,
            docDateError: false,
            issuerError: false,
            kindError: false
        };
    }

    componentDidMount() {
        if (this.props.document.edit && this.props.document.id) {
            let document = this.props.personDocuments.find(d => d.id === this.props.document.id);
            if (document) {
                this.setState({
                    id: document.id,
                    image: document.image,
                    file: null,
                    docSeries: document.docSeries,
                    comments: document.comments,
                    docNumber: document.docNumber,
                    kind: document.kind ? {value: document.kind.id, label: document.kind.name} : {
                        value: null,
                        label: null
                    },
                    docDate: document.docDate,
                    type: document.type ? {value: document.type.id, label: document.type.name} : {
                        value: null,
                        label: null
                    },
                    issuer: null,
                    name: document.name,
                    loading: true,
                    saving: false

                })
            }
        }
        this.getDocumentTypes();
    }

    getDocumentTypes = () => {
        DocumentService.getDocumentTypes()
            .then((data) => {
                if (data.data) {
                    let types = [];
                    data.data.forEach(t => {
                        types.push({value: t.id, label: t.name})
                    });
                    let type = {
                        value: null,
                        label: ""
                    };
                    if (this.props.document.type && this.props.document.new) {
                        let t = data.data.find(t => t.name === this.props.document.type);
                        if (t) {
                            type.value = t.id;
                            type.label = t.name;
                        }
                        this.setState({
                            type: type
                        });
                    }
                    this.setState({
                        types: types
                    });
                    if (this.props.document.type || this.state.type.value) {
                        this.getDocumentKinds();
                    } else {
                        this.setState({loading: false});
                    }
                }
            })
            .catch(() => {
                this.setState({loading: false});
            })
    };

    getDocumentKinds = () => {
        if (this.state.type && this.state.type.value) {

            DocumentService.getDocumentKind(this.state.type.value)
                .then((data) => {
                    let kinds = [];
                    data.data.forEach(t => {
                        kinds.push({value: t.id, label: t.name})
                    });

                    this.setState({
                        kinds: kinds,
                        loading: false
                    });

                    if (this.state.kind && this.state.kind.value && data.data.findIndex(k => k.id === this.state.kind.value) === -1) {
                        this.setState({
                            kind: {
                                value: null,
                                label: null
                            }
                        });
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                })
        }
    };

    handleTypeChange = (value) => {
        this.setState({
            type: value
        });

        setTimeout(() => {
            this.getDocumentKinds()
        });
    };
    handleKindChange = (value) => {
        this.setState({
            kind: value
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if (!this.validateDocument()) {
            return;
        }

        this.setState({
            saving: true
        });
        let fileRef = this.state.image;
        if (this.state.file) {
            let data = new FormData();
            const file = this.state.file;
            data.append('file', file);
            let result = await DocumentService.uploadFile(data);
            if (result.data && result.data.fileRef) {
                fileRef = result.data.fileRef;
            } else {
                //TODO: Показать ошибку сохранения
                this.setState({
                    saving: false
                });
            }
        }
        let document = {
            image: fileRef,
            name: this.state.name,
            docSeries: this.state.docSeries,
            comments: this.state.comments,
            docNumber: this.state.docNumber,
            person: {id: this.props.person.id},
            kind: this.state.kind && this.state.kind.value ? {id: this.state.kind.value} : null,
            type: {id: this.state.type.value},
            issuer: this.state.issuer,
        };
        if (this.state.id) {
            document.id = this.state.id;
        }
        if (this.props.document.new) {
            toast.promise(
                this.saveDocument(document),
                {
                    pending: 'Сохранение',
                    success: 'Документ сохранен',
                    error: 'Ошибка сохранения',
                }
            )
                .then(() => {eventBus.dispatch('closeDocument');})
                .catch();

        } else if (this.props.document.edit && this.props.document.id) {
            toast.promise(
                this.updateDocument(document),
                {
                    pending: 'Сохранение',
                    success: 'Документ сохранен',
                    error: 'Ошибка сохранения',
                }
            )
                .then(() => {eventBus.dispatch('closeDocument');})
                .catch();
        }
    };
    saveDocument = (document) => {
        const {dispatch} = this.props;
        return dispatch(saveNewUserDocument(document))
            .then(() => {
                this.setState({
                    saving: false
                });
                return Promise.resolve();
            })
            .catch(() => {
                this.setState({
                    saving: false
                });
                return Promise.reject()
            })
    };
    updateDocument = (document) => {
        const {dispatch} = this.props;
        return dispatch(updateUserDocument(document))
            .then(() => {
                this.setState({
                    saving: false
                });
                return Promise.resolve();
            })
            .catch(() => {
                //TODO: Показать ошибку сохранения
                this.setState({
                    saving: false
                });
                return Promise.reject()
            })
    };
    validateDocument = () => {
        this.setState({
            nameError: false,
            kindError: false,
            imageError: false,
            docNumberError: false,
            docDateError: false,
            docSeriesError: false,
            issuerError: false
        });
        if ((this.state.kinds && this.state.kinds.length) && (!this.state.kind || !this.state.kind.label || !this.state.kind.label.length)) {
            toast.error("Укажите вид документа");
            this.setState({
                kindError: true
            });
            return false
        }
        if (this.state.type && this.state.type.label && this.state.type.label === 'Документ, удостоверяющий личность, гражданство' && (!this.state.docNumber || !this.state.docNumber.length)) {
            toast.error("Укажите номер документа");
            this.setState({
                docNumberError: true
            });
            return false
        }
        if (this.state.kind && this.state.kind.label && this.state.kind.label === 'Паспорт гражданина РФ' && (!this.state.docSeries || !this.state.docSeries.length)) {
            toast.error("Укажите серию документа");
            this.setState({
                docSeriesError: true
            });
            return false
        }
        if (this.state.type && this.state.type.label && this.state.type.label === 'Документ, удостоверяющий личность, гражданство' && (!this.state.docDate || !this.state.docDate.length)) {
            toast.error("Укажите дату выдачи документа");
            this.setState({
                docDateError: true
            });
            return false
        }
        if (!this.state.name || !this.state.name.length) {
            toast.error("Укажите название документа");
            this.setState({
                nameError: true
            });
            return false;
        }
        if (this.state.kind && this.state.kind.label && this.state.kind.label === 'Паспорт гражданина РФ' && (!this.state.issuer || !this.state.issuer.length)) {
            toast.error("Укажите, кем выдан документ");
            this.setState({
                issuerError: true
            });
            return false
        }
        if (!this.state.image && !this.state.file) {
            toast.error("Добавьте скан-копию документа");
            this.setState({
                imageError: true
            });
            return false
        }
        return true;
    };
    closeDocument = () => {
        //TODO: проверить наличие изменений
        eventBus.dispatch('closeDocument');
    };

    renderFile = (ref) => {
        if (!ref) {
            return (
                <div className="doc-icon">
                    <FontAwesomeIcon icon={faFile} size={"xl"} color="#232527"/>
                </div>
            )
        }
        let [url, name] = ref.split('?name=');
        name = decodeURI(name);
        let fileType = name.split('.').pop();
        return (
            <div className="d-flex align-items-center mt-2 pb-1 document-modal">
                <div className="doc-icon me-2">
                    {
                        fileType === 'doc' || fileType === 'docx' ?
                            <FontAwesomeIcon icon={faFileWord} size={"xl"} color="#295394"/>
                            : fileType === 'xls' || fileType === 'xlsx' ?
                            <FontAwesomeIcon icon={faFileExcel} size={"xl"} color="#1f6f45"/>
                            : fileType === 'pdf' ?
                                <FontAwesomeIcon icon={faFilePdf} size={"xl"} color="#f23024"/> :
                                <FontAwesomeIcon icon={faFile} size={"xl"} color="#232527"/>}
                </div>
                <div className="d-flex align-items-center justify-content-between flex-grow-1 flex-wrap">
                    <a target="_blank"
                       href={serverUrl + "/rest/files?access_token=" + TokenService.getLocalAccessToken() + "&fileRef=" + url + '?name=' + encodeURIComponent(name) + "&attachment=true"}
                       className="mt-1 mb-1">{name}</a>
                    <Button variant="link" size="sm" className="mt-1 mb-1 text-danger fw-bolder" onClick={()=> {this.setState({image: null})}}>Удалить</Button>
                </div>
            </div>
        )
    };

    render() {
        const docNumber = this.state.docNumber ? this.state.docNumber : "";
        const docSeries = this.state.docSeries ? this.state.docSeries : "";
        const name = this.state.name ? this.state.name : "";
        const issuer = this.state.issuer ? this.state.issuer : "";
        const comments = this.state.comments ? this.state.comments : "";

        return (
            <Row>
                {!this.props.document.type && !this.props.document.edit &&
                <Col xs={12} lg={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Тип документа</Form.Label>
                        <Select options={this.state.types}
                                placeholder={''}
                                isDisabled={this.state.loading || this.state.saving}
                                onChange={this.handleTypeChange}
                                value={this.state.type}/>
                    </Form.Group>
                </Col>
                }
                {this.state.kinds.length || (this.state.kind && this.state.kind.value) ?
                    <Col xs={12} lg={12}>
                        <Form.Group className="mb-3 required">
                            <Form.Label>Вид документа</Form.Label>
                            <Select options={this.state.kinds}
                                    placeholder={''}
                                    className={this.state.kindError? 'is-invalid document-select-container' : 'document-select-container'}
                                    classNamePrefix="document-select"
                                    isDisabled={this.state.loading || this.state.saving}
                                    onChange={this.handleKindChange}
                                    value={this.state.kind}/>
                        </Form.Group>
                    </Col>
                    :
                    null
                }
                <Col xs={12} lg={5}>
                    <Form.Group className={this.state.type && this.state.type.label && this.state.type.label === "Документ, удостоверяющий личность, гражданство"? "required mb-3" : "mb-3"}>
                        <Form.Label>Номер</Form.Label>
                        <input className={this.state.docNumberError? "is-invalid form-control" : "form-control"}
                               value={docNumber}
                               onChange={(e) => {
                                   this.setState({docNumber: e.target.value.length ? e.target.value : null})
                               }}
                               required
                               disabled={this.state.loading || this.state.saving}
                               type="text"/>
                    </Form.Group>
                </Col>
                <Col xs={6} lg={3}>
                    <Form.Group className={this.state.type && this.state.type.label && this.state.type.label === "Документ, удостоверяющий личность, гражданство"
                    && this.state.kind && this.state.kind.label && this.state.kind.label === "Паспорт гражданина РФ"? "required mb-3" : "mb-3"}>
                        <Form.Label>Серия</Form.Label>
                        <input className={this.state.docSeriesError ? "is-invalid form-control" : "form-control"}
                               value={docSeries}
                               onChange={(e) => {
                                   this.setState({docSeries: e.target.value.length ? e.target.value : null})
                               }}
                               disabled={this.state.loading || this.state.saving}
                               required
                               type="text"/>
                    </Form.Group>
                </Col>
                <Col xs={6} lg={4}>
                    <Form.Group className={this.state.type && this.state.type.label && this.state.type.label === "Документ, удостоверяющий личность, гражданство"? "required mb-3" : "mb-3"}>
                        <Form.Label>Дата выдачи</Form.Label>
                        <Datepicker
                            className={this.state.docDateError ? "is-invalid form-control" : "form-control"}
                            selected={this.state.docDate && this.state.docDate.length ? new Date(this.state.docDate) : null}
                            dateFormat="dd.MM.yyyy"
                            locale={'ru'}
                            showMonthDropdown
                            showYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            disabled={this.state.loading || this.state.saving}
                            onChange={(date) => {
                                this.setState({docDate: date.toISOString()})
                            }}
                            required/>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={12}>
                    <Form.Group className="mb-3 required">
                        <Form.Label>Название</Form.Label>
                        <input className={this.state.nameError ? "is-invalid form-control" : "form-control"}
                               value={name}
                               onChange={(e) => {
                                   this.setState({name: e.target.value.length ? e.target.value : null})
                               }}
                               disabled={this.state.loading || this.state.saving}
                               required
                               type="text"/>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={12}>
                    <Form.Group className={this.state.type && this.state.type.label && this.state.type.label === "Документ, удостоверяющий личность, гражданство"
                    && this.state.kind && this.state.kind.label && this.state.kind.label === "Паспорт гражданина РФ"? "required mb-3" : "mb-3"}>
                        <Form.Label>Кем выдан</Form.Label>
                        <input className={this.state.issuerError ? "is-invalid form-control" : "form-control"}
                               value={issuer}
                               onChange={(e) => {
                                   this.setState({issuer: e.target.value.length ? e.target.value : null})
                               }}
                               disabled={this.state.loading || this.state.saving}
                               required
                               type="text"/>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Комментарий</Form.Label>
                        <textarea className={this.state.error ? "is-invalid form-control" : "form-control"}
                                  value={comments}
                                  disabled={this.state.loading || this.state.saving}
                                  onChange={(e) => {
                                      this.setState({comments: e.target.value.length ? e.target.value : null})
                                  }}
                                  required/>
                    </Form.Group>
                </Col>
                <Col xs={12} lg={12}>
                    <Form.Group controlId="formFile" className={"required mb-3"}>
                        <Form.Label>Скан-копия:</Form.Label>
                        {this.state.image ?
                            this.renderFile(this.state.image)
                            :
                            <input className={this.state.imageError ? "is-invalid form-control" : "form-control"}
                                   required
                                   onChange={(e) => {
                                       this.setState({file: e.target.files && e.target.files[0] ? e.target.files[0] : null})
                                   }}
                                   disabled={this.state.loading || this.state.saving}
                                   ref={this.fileInput}
                                   type="file"/>
                        }
                    </Form.Group>
                </Col>
                {this.state.loading ?
                    <Col xs={12} lg={12} className="justify-content-center d-flex align-items-center"
                         style={{height: 38}}>
                        <Spinner animation="border" size="sm" variant="primary" className="me-1"/> Загрузка
                    </Col>
                    :
                    <Col xs={12} lg={12} className="justify-content-between d-flex">
                        <Button variant="outline-danger" onClick={this.closeDocument}
                                disabled={this.state.loading || this.state.saving}>
                            <FontAwesomeIcon icon={faTimes} className="me-1"/> Закрыть
                        </Button>
                        <Button onClick={this.handleSubmit} className="ms-3"
                                disabled={this.state.loading || this.state.saving}>
                            {this.state.saving ?
                                <Spinner animation="border" size="sm" variant="light" className="me-1"/>
                                :
                                <FontAwesomeIcon icon={faCheck} className="me-1"/>
                            }
                            Сохранить
                        </Button>
                    </Col>
                }
            </Row>
        );
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const {personDocuments, person} = state.user;

    return {
        person,
        isLoggedIn,
        personDocuments
    };
}

export default connect(mapStateToProps)(Document);
