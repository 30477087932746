import axiosInstance from "./api";
import TokenService from "./token.service";
import { refreshToken } from "../actions/auth";
import Qs from "qs";
import eventBus from "../common/EventBus";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token && config.url !== "/oauth/token") {
        //config.headers["Authorization"] = 'not set';
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      } else {
        config.headers["Authorization"] = 'Basic Y2xpZW50OnNlY3JldA==';
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
      async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/oauth/token" && err.response) {
        if ((err.response.status === 401) && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            let data = Qs.stringify({
              'grant_type': 'refresh_token',
              'refresh_token': TokenService.getLocalRefreshToken(),
            });
            const rs = await axiosInstance.post("/oauth/token", data);

            const accessToken = rs.data.access_token;

            dispatch(refreshToken(accessToken));
            TokenService.updateLocalAccessToken(accessToken);
            eventBus.dispatch('login');
            setTimeout(() => {return axiosInstance(originalConfig);})
          } catch (_error) {
            return await Promise.reject(_error);
          }
        }
        else if ((err.response.status === 401) && originalConfig._retry) {
          eventBus.dispatch("logout");
          return Promise.reject(err);
        }
        else {
          //eventBus.dispatch("logout");
          return Promise.reject(err);
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
