import React, {Component} from "react";
import {connect} from "react-redux";
import {Spinner, Card} from "react-bootstrap";
import {confirmEmail} from "../actions/auth";
import EventBus from "../common/EventBus";

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            success: false
        };
    }

    componentDidMount() {
        const {dispatch, history, access} = this.props;
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        if (this.props.user && this.props.user.data && this.props.user.data.emailConfirmed) {
            history.push('/profile');
        }
        if (!token) {
            this.setState({
                loading: false,
                error: true,
                errorHeader: "Произошла ошибка",
                errorText: "Ссылка для подтверждения электронной почты не содержит кода подтверждения"
            });
            return;
        }
        dispatch(confirmEmail(token))
            .then(() => {
                if (this.props.access) {
                    EventBus.dispatch("logout");
                }
                this.setState({
                    loading: false,
                    error: false
                });
            })
            .catch((data) => {
                this.setState({
                    loading: false,
                    error: true,
                    errorHeader: data.header,
                    errorText: data.text
                });
            });
    }

    goToPage = (e, page) => {
        const {history} = this.props;
        e.preventDefault();
        history.push(page);
    };

    render() {

        return (
            <div className="container pt-5 mt-5">
                {this.state.loading &&
                <div className="d-flex flex-grow-1 justify-content-center align-items-center mt-5 h-75">
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                }
                {
                    !this.state.loading && !this.state.error &&
                    <Card className="p-4">
                        <Card.Body>
                            <Card.Title className={"mb-4 h3"}>Адрес электронной почты потвержден</Card.Title>
                            <Card.Text>
                                Теперь вам доступны все возможности системы «Электронный колледж». Вам необходимо
                                повторно авторизоваться
                            </Card.Text>
                            <Card.Link role="button" className="cursor" onClick={(e) => {
                                this.goToPage(e, '/login')
                            }}>Войти в систему</Card.Link>
                        </Card.Body>
                    </Card>
                }
                {
                    !this.state.loading && this.state.error &&
                    <Card className="p-4">
                        <Card.Body>
                            <Card.Title className={"mb-4 h3"}>{this.state.errorHeader}</Card.Title>
                            <Card.Text>
                                {this.state.errorText}
                            </Card.Text>
                            <Card.Link role="button" onClick={(e) => {
                                this.goToPage(e, '/')
                            }}>Вернуться на главаную</Card.Link>
                        </Card.Body>
                    </Card>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const access = state.auth;
    const user = state.user;
    return {
        access,
        user
    };
}

export default connect(mapStateToProps)(Confirm);
