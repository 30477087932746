import axios from "axios";

export const serverUrl = "https://demo.ecollege.center";
//export const serverUrl = "http://localhost:8090";
//export const serverUrl = "http://172.17.249.149:8080";
const instance = axios.create({
  baseURL: serverUrl
});
export const processError = (error) => {
  let result = {
    header: null,
    text: null
  };
  if (error.response) {
    result.header = "Произошла ошибка" + error.response.status;
    if (error.response.data && error.response.data.error_description) {
      result.text = error.response.data.error_description;
    }
  } else if (error.request) {
    result.header = "Ошибка соединения с сервером";
    result.text = "Проверьте наличие соединения с интернетом"
  } else {
    result.header = "Произошла неизвесная ошибка";
    result.text = "Попробуйте позже или обратитесь в тех поддержку"
  }
  return result
};

export default instance;
