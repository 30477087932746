import React, {Component} from "react";

import {getIntakes, getSpecialities} from "../../actions/intakes";
import EventBus from "../../common/EventBus";
import {serverUrl} from "../../services/api";
import {Collapse, Pagination, FormControl, Form, Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {faExternalLink, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {history} from "../../helpers/history";

class CollegeTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            timeout: 300,
            requestModal: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.college.id !== prevProps.college.id) {
            this.setState({
                timeout: 0
            });
            this.setState({
                open: false
            })
        }

    }

    getIntakesCount = () => {
        let count = 0;
        const {college} = this.props;
        if (college.specialities) {
            college.specialities.forEach(s => {
                if (s.intakes) {
                    count = count + s.intakes.length
                }
            })
        }
        return count
    };

    onRequest = (id) => {
        this.props.history.push('/admission/request/' + id);
    };

    render() {
        const {college} = this.props;
        return (
            <div className="collegeRow" key={college.id}>
                <div className="collegeImage"
                     style={{backgroundImage: "url(" + serverUrl + "/rest/files?fileRef=" + college.iconUrl + ")"}}/>
                <div className="row">
                    <div className="col-12">
                        <h3>{college.shortName && college.shortName.length ? college.fullName : college.fullName}</h3>
                        <div className="college-info d-flex justify-content-between align-items-center flex-wrap">
                            <a className="toggle" onClick={() => this.setState({open: !this.state.open, timeout: 300})}>
                                Специальности / открытые
                                наборы: {college.specialities && college.specialities.length} / {this.getIntakesCount()}
                            </a>
                            <div className="mt-2 mb-2">
                                {(college.district && college.district.name) &&
                                <span>
                                        {college.district.name + " район"}
                                    </span>
                                }
                                {(college.homeSiteURL && college.homeSiteURL.length) &&
                                <a target="_blank" href={college.homeSiteURL}>
                                    <FontAwesomeIcon icon={faExternalLink}/> Сайт колледжа
                                </a>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Collapse in={this.state.open} timeout={this.state.timeout} unmountOnExit>
                    <div className="specialty-list">
                        {
                            college.specialities && college.specialities.map(s => {
                                return (
                                    <div className="specialty-row" key={s.id}>
                                        <div
                                            className="header d-flex justify-content-between align-items-center flex-wrap">
                                            <h4 className="d-flex align-items-center">
                                                <span>{s.specialty.code}</span> {s._instanceName}</h4>

                                            <span>
                                                {s.studyForm === "SF_FULLTIME" && "Очная форма обучения"}
                                                {s.studyForm === "SF_CORRESPONDENCE" && "Заочная форма обучения"}
                                                {s.studyForm === "SF_EVENING" && "Вечерняя форма обучения"}
                                            </span>
                                            {(!s.intakes || !s.intakes.length) &&
                                            <div className="no-intakes">Нет наборов</div>}
                                        </div>
                                        <div className="intakes-list">
                                            {(s.intakes && s.intakes.length) &&
                                            s.intakes.map(intake => {
                                                if (intake.intakePeriodStart && intake.intakePeriodEnd) {
                                                    return (
                                                        <div key={intake.id}
                                                             className="intake-row d-flex justify-content-between pt-3 pb-3 align-items-center">
                                                            <div>
                                                                { new Date() >= new Date(intake.intakePeriodStart) && new Date() <= new Date(intake.intakePeriodEnd) &&
                                                                    <h6>Набор идет</h6>
                                                                }
                                                                { new Date() < new Date(intake.intakePeriodStart) &&
                                                                    <h6>Набор запланирован</h6>
                                                                }
                                                                { new Date() > new Date(intake.intakePeriodEnd) &&
                                                                    <h6>Набор завершен</h6>
                                                                }
                                                                {intake.year && <span
                                                                    className="info">{"Учебный период: " + intake.year}</span>}
                                                            </div>
                                                            <div>
                                                                {intake.intakePeriodStart && <span
                                                                    className="info">{"Начало: " + new Date(intake.intakePeriodStart).toLocaleDateString()}</span>}
                                                                {intake.intakePeriodEnd && <span
                                                                    className="info">{"Завершение: " + new Date(intake.intakePeriodEnd).toLocaleDateString()}</span>}
                                                            </div>
                                                            <div>
                                                                {intake.numberOfVacancies &&
                                                                <span
                                                                    className="info">{"Количество мест: " + intake.numberOfVacancies}</span>
                                                                }
                                                                {intake.budgetType &&
                                                                <span className="info">
                                                                    {intake.budgetType === "BT_BUDGET" && "Бюджетные места"}
                                                                    {intake.budgetType === "BT_COMMERCIAL" && "Платные места"}
                                                                </span>
                                                                }
                                                            </div>
                                                            <div>
                                                                {intake.status === "IS_ACTIVE"
                                                                && intake.intakePeriodStart && new Date() >= new Date(intake.intakePeriodStart)
                                                                && intake.intakePeriodEnd && new Date() <= new Date(intake.intakePeriodEnd)
                                                                && this.props.user && this.props.user.applicants
                                                                && this.props.user.applicants.filter(a => a.intake.id === intake.id && a.status !== "AS_REFUSED" && a.status !== "AS_DISMISSED").length === 0 &&
                                                                <Button onClick={() => {
                                                                    this.onRequest(intake.id)
                                                                }}>
                                                                    Подать заявление
                                                                </Button>
                                                                }
                                                                {intake.status === "IS_ACTIVE"
                                                                && this.props.user && this.props.user.applicants
                                                                && this.props.user.applicants.filter(a => a.intake.id === intake.id && a.status !== "AS_REFUSED" && a.status !== "AS_DISMISSED").length > 0 &&
                                                                <Button disabled={true}>
                                                                    Заявление подано
                                                                </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Collapse>
                <Modal aria-labelledby="contained-modal-title-vcenter"
                       centered
                       show={this.state.requestModal}>
                    <Modal.Header>
                        <Modal.Title>Подача заявлений</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="mt-3">Подача заявлений будет открыта позже</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => {
                            this.setState({requestModal: false})
                        }} variant="primary">Закрыть</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

class Intakes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colleges: [],
            allColleges: [],
            loading: false,
            page: 0,
            rowsPerPage: 10,
            pageTrim: 5,
            mode: 1,
            searchText: ""
        };
    }

    componentDidMount() {
        const {data} = this.props;
        this.getColleges();
        this.setState({
            loading: true
        });
    }

    getColleges = () => {
        const {dispatch} = this.props;
        dispatch(getSpecialities())
            .then(() => {
                this.getIntakes()
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
            });
    };
    getIntakes = () => {
        const {dispatch} = this.props;
        dispatch(getIntakes())
            .then(() => {
                this.setState({
                    loading: false
                });
                this.processData();
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
            });
    };

    processData = () => {
        if (this.state.mode === 1) {
            let specialties = this.props.data.colleges;

            specialties.sort(function (a, b) {
                let nameA = a.specialty.code.toUpperCase(); // ignore upper and lowercase
                let nameB = b.specialty.code.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            let intakes = this.props.data.intakes;
            let colleges = [];
            specialties.forEach((spec) => {
                let college = spec.college;

                let currentIntakes = intakes.filter(i => i.specialty.id === spec.id);
                if (currentIntakes && currentIntakes.length) {
                    spec.intakes = [];
                    currentIntakes.forEach(i => {
                        let intake = Object.assign({}, i);
                        delete intake.specialty;
                        spec.intakes.push(i)
                    });
                }

                let collegeIndex = colleges.findIndex(c => c.id === college.id);
                if (collegeIndex === -1) {
                    college.specialities = [];
                    let specCopy = Object.assign({}, spec);
                    delete specCopy.college;
                    college.specialities.push(specCopy);
                    colleges.push(college);
                } else {
                    let specCopy = Object.assign({}, spec);
                    delete specCopy.college;
                    colleges[collegeIndex].specialities.push(specCopy);
                }
            });
            colleges.sort(function (a, b) {
                let nameA = a.fullName.toUpperCase(); // ignore upper and lowercase
                let nameB = b.fullName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({
                allColleges: colleges,
                colleges: colleges,
                loading: false
            })
        }

    };


    handleDataError = (error) => {
        this.setState({
            error: (error.response && error.response.data) || error.message || error.toString(),
            loading: false
        });
        if (error.response && (error.response.status === 401 || (error.response.status === 400 && error.response.data.error === 'invalid_grant'))) {
            EventBus.dispatch("logout");
        }
    };

    handleChangePage = (newPage) => {
        this.setState({
            page: newPage
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
    };

    renderPagination = () => {
        return (
            this.state.colleges.length / this.state.rowsPerPage > 9 ?
                <Pagination size="sm" className="mb-0">

                    <Pagination.Prev
                        disabled={this.state.page <= 0}
                        onClick={() => this.handleChangePage(this.state.page - 1)}
                    />
                    <Pagination.Item
                        onClick={() => this.handleChangePage(0)}
                        className="d-none d-sm-block"
                        active={this.state.page === 0}
                    >
                        1
                    </Pagination.Item>
                    <Pagination.Item
                        className="d-sm-none"
                        active
                    >
                        {this.state.page + 1}
                    </Pagination.Item>

                    {this.state.page < this.state.pageTrim - 1 &&
                    <>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(1)}
                            active={this.state.page === 1}>{2}</Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(2)}
                            active={this.state.page === 2}>{3}</Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(3)}
                            active={this.state.page === 3}>{4}</Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(5)}
                            active={this.state.page === 4}>{5}</Pagination.Item>
                        <Pagination.Ellipsis
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(6)}
                        />
                    </>
                    }
                    {(this.state.page >= this.state.pageTrim - 1 && this.state.page <= Math.floor(this.state.colleges.length / this.state.rowsPerPage) - this.state.pageTrim + 1) &&
                    <>
                        <Pagination.Ellipsis
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(this.state.page - 2)}
                        />
                        <Pagination.Item
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(this.state.page - 1)}
                        >{this.state.page}</Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            active>{this.state.page + 1}</Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(this.state.page + 1)}>
                            {this.state.page + 2}</Pagination.Item>
                        <Pagination.Ellipsis
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(this.state.page + 2)}
                        />
                    </>
                    }
                    {this.state.page > Math.floor(this.state.colleges.length / this.state.rowsPerPage) - this.state.pageTrim + 1 &&
                    <>
                        <Pagination.Ellipsis
                            className="d-none d-sm-block"
                            onClick={() => this.handleChangePage(this.state.page - 5)}
                        />
                        <Pagination.Item
                            className="d-none d-sm-block"
                            active={this.state.page === Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 4}
                            onClick={() => this.handleChangePage(Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 4)}
                        >
                            {Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 3}
                        </Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            active={this.state.page === Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 3}
                            onClick={() => this.handleChangePage(Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 3)}
                        >
                            {Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 2}
                        </Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            active={this.state.page === Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 2}
                            onClick={() => this.handleChangePage(Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 2)}
                        >
                            {Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 1}
                        </Pagination.Item>
                        <Pagination.Item
                            className="d-none d-sm-block"
                            active={this.state.page === Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 1}
                            onClick={() => this.handleChangePage(Math.floor(this.state.colleges.length / this.state.rowsPerPage) - 1)}
                        >
                            {Math.floor(this.state.colleges.length / this.state.rowsPerPage)}
                        </Pagination.Item>
                    </>
                    }

                    <Pagination.Item
                        className="d-none d-sm-block"
                        active={this.state.page === Math.floor(this.state.colleges.length / this.state.rowsPerPage)}
                        onClick={() => this.handleChangePage(Math.floor(this.state.colleges.length / this.state.rowsPerPage))}>
                        {Math.floor(this.state.colleges.length / this.state.rowsPerPage) + 1}
                    </Pagination.Item>
                    <Pagination.Next
                        disabled={this.state.page >= Math.floor(this.state.colleges.length / this.state.rowsPerPage)}
                        onClick={() => this.handleChangePage(this.state.page + 1)}/>

                </Pagination>
                :
                <Pagination size="sm" className="mb-0">

                    <Pagination.Prev
                        disabled={this.state.page <= 0}
                        onClick={() => this.handleChangePage(this.state.page - 1)}
                    />
                    <Pagination.Next
                        disabled={this.state.page >= Math.floor(this.state.colleges.length / this.state.rowsPerPage)}
                        onClick={() => this.handleChangePage(this.state.page + 1)}/>

                </Pagination>
        )
    };

    handleKeyPress(e) {
        if (e.key === "Enter") {
            this.handleSearch();
            e.preventDefault();
        }
        if (e.key === "Backspace") {
            e.preventDefault();
        }
    }

    handleSearch = () => {
        let colleges = this.state.allColleges;
        let search = this.state.searchText.toUpperCase();
        if (search.length > 0) {
            let searchedColleges = colleges.filter(function (college) {
                if (college.fullName && college.fullName.toUpperCase().indexOf(search) !== -1) {
                    return college;
                }
                if (college.shortName && college.shortName.toUpperCase().indexOf(search) !== -1) {
                    return college;
                }
                if (college.shortName && college.shortName.toUpperCase().indexOf(search) !== -1) {
                    return college;
                }
                return false;
            });
            this.setState({
                colleges: searchedColleges,
                page: 0
            })
        } else {
            this.setState({
                colleges: colleges,
                page: 0
            })
        }

    };

    render() {
        const {history} = this.props;
        return (
            <div className="container-fluid">
                <div className="main-content d-flex">
                    <div className="filters d-none"/>
                    <div className="main-table flex-grow-1">
                        <div className="d-flex justify-content-between flex-wrap">
                            <div className="mb-4">
                                <Form className="d-flex">
                                    <FormControl
                                        disabled={this.state.loading}
                                        type="text"
                                        style={{width: 280}}
                                        placeholder="Поиск колледжа"
                                        className="me-2"
                                        value={this.state.searchText}
                                        onKeyPress={(e) => {
                                            this.handleKeyPress(e)
                                        }}
                                        onChange={(e) => {
                                            this.setState({searchText: e.target.value})
                                        }}
                                        aria-label="Search"
                                    />
                                </Form>
                            </div>
                            {this.renderPagination()}
                        </div>
                        {this.state.loading &&
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center text-primary"
                             style={{minHeight: 460}}>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        }
                        {!this.state.loading &&
                        <div className="collegeTable">
                            {this.state.rowsPerPage > 0 ?
                                this.state.colleges.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(college => {
                                    return (
                                        <CollegeTableRow key={college.id} college={college} history={history} user={this.props.user}/>
                                    )
                                })
                                :
                                this.state.colleges.map(college => {
                                    return (<CollegeTableRow key={college.id} college={college} history={history} user={this.props.user}/>)
                                })
                            }
                        </div>
                        }
                        <div className="mt-4 d-flex justify-content-between align-items-center">
                            <div className="pagination-counter">
                                {this.state.colleges.length &&
                                <span>
                                    Показано с {this.state.page * this.state.rowsPerPage + 1}{" по "}
                                    {this.state.page * this.state.rowsPerPage + this.state.colleges.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).length}
                                    {" из "} {this.state.colleges.length}
                                </span>
                                }
                                {!this.state.colleges.length &&
                                <span>Показано 0</span>
                                }
                            </div>
                            {this.renderPagination()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const data = state.intakes;
    const user = state.user;
    return {
        data,
        user
    };
}

export default connect(mapStateToProps)(Intakes);
