import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class notFound extends Component {


    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-6 align-self-center text-center">
                        <img
                            alt='Logo'
                            className="mb-4 m-auto d-block"
                            src={process.env.PUBLIC_URL + '/resources/images/404.svg'}
                            width="90%"
                            style={{maxWidth: "400px"}}
                        />
                        <h1 style={{fontSize:40, fontWeight:"Bold", fontFamily:"Roboto Slab"}}>Упс! 404 ошибка</h1>
                        <p>Страница, которую вы ищите, не найдена. Вернуться на <Link to="/">главную</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}
