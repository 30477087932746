import {
    GET_SPECIALTY_SUCCESS,
    GET_SPECIALTY_FAIL,
    GET_INTAKES_SUCCESS,
    GET_INTAKES_FAIL
} from "../actions/types";

const initialState = {
    collegesIsLoaded: false,
    intakesIsLoaded: false,
    colleges: [],
    intakes: [],
    errorColleges: false,
    errorIntakes: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SPECIALTY_SUCCESS:
            return {
                ...state,
                colleges: payload,
                collegesIsLoaded: true,
                errorColleges: false
            };
        case GET_SPECIALTY_FAIL:
            return {
                ...state,
                colleges: [],
                collegesIsLoaded: false,
                errorColleges: true
            };
        case GET_INTAKES_SUCCESS:
            return {
                ...state,
                intakes: payload,
                intakesIsLoaded: true,
                errorIntakes: false
            };
        case GET_INTAKES_FAIL:
            return {
                ...state,
                intakes: [],
                intakesIsLoaded: false,
                errorIntakes: true
            };
        default:
            return state;
    }
}
