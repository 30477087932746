import api from './api';
import Qs from "qs";

class IntakeService {
    getColleges() {
        return api.get('/rest/entities/College');
    }
    getSpecialties() {
        return api.get('/rest/entities/CollegeSpecialty?fetchPlan=rest');
    }
    getIntakes() {
        return api.get('/rest/entities/Intake?fetchPlan=_base');
    }
    getSpecialityGroups() {
        return api.get('/rest/entities/SpecialtyGroup');
    }
    getIntake(id) {
        return api.get('/rest/entities/Intake/' + id + '?fetchPlan=full');
    }
    getIntakeSubjects(id) {
        let data = {
            "filter": {
                "conditions": [
                    {
                        "property": "specialty",
                        "operator": "=",
                        "value": id
                    }
                ]
            }
        };
        return api.post('/rest/entities/SignificantSchoolSubject/search', data);
    }
    getIntakeDocuments(id) {
        let data = {
            "intake": id
        };
        return api.post('/rest/queries/IntakeDocument/documentsByIntake', data);
    }
    getCollegeDocuments(id) {
        let data = {
            "college": id
        };
        return api.post('/rest/queries/CollegeDocument/documentsByCollege', data);
    }
    getSubjects() {
        return api.get('/rest/entities/SchoolSubject')
    }
}

export default new IntakeService();
