import React, {Component} from "react";
import {getApplicants, getTestParticipants} from "../actions/user";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faCalendar, faFileContract, faPersonChalkboard} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";

const testsType = [
    {
        value: "TT_EXAM",
        label: "экзамен"
    }, {
        value: "TT_TESTING",
        label: "тестирование"
    }, {
        value: "TT_INTERVIEW",
        label: "собеседование"
    }, {
        value: "TT_ESSAYS",
        label: "эссе"
    }, {
        value: "TT_OTHER",
        label: "иная форма"
    }
];

class Tests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tests: [],
            filters: false,
            loading: true
        };
    }

    componentDidMount() {
        this.getTests()
    }

    getTests = () => {
        this.setState({
            loading: true
        });
        const {dispatch} = this.props;
        dispatch(getTestParticipants())
            .then(() => {
                this.setState({
                    tests: this.props.user.testParticipants,
                    loading: false
                })
            })
            .catch(() => {
                this.setState({
                    tests: this.props.user.testParticipants,
                    loading: false
                })
            })
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="main-content">
                    {!this.state.loading ?
                        <div>
                            {this.state.tests.sort(function (a, b) {
                                return new Date(b.test.startTime.createdDate) - new Date(a.test.startTime.createdDate)
                            }).map(t => {
                                const specialties = this.props.user.applicants.filter(a => t.test.details.intakes.findIndex(i => i.id === a.intake.id) !== -1);
                                const testType = t.test.details.testType && testsType.find(tt => tt.value === t.test.details.testType) ? testsType.find(tt => tt.value === t.test.details.testType).label : "не указан";
                                return (
                                    <div key={t.id} className="application test d-flex flex-wrap flex-lg-nowrap">
                                        <div className="flex-grow-1">
                                            <h6>
                                                {new Date(t.test.startTime).toLocaleDateString()} в {new Date(t.test.startTime).toLocaleTimeString()}
                                                . Предмет испытания: {t.test.details.subject.name}
                                                . Тип испытания: {testType}
                                            </h6>
                                            <p className="text-secondary">
                                                {specialties && specialties.length > 0 &&
                                                <span>Колледж - «{specialties[0].intake.specialty.college.fullName}». </span>
                                                }
                                                {t.test.place &&
                                                <span>Место проведения - «{t.test.place}». </span>
                                                }
                                            </p>
                                            {specialties && specialties.length > 0 &&
                                            <div className="d-flex flex-wrap">
                                                {specialties.map((s, index) => {
                                                    return (
                                                        <div key={index + "_" + s.id} className="intake">{s.intake.specialty.specialty.code} - {s.intake.specialty.specialty.name}</div>
                                                    )
                                                })}
                                            </div>
                                            }
                                        </div>
                                        <div className="d-flex flex-lg-column">
                                            <div className="mb-2">
                                                <b>Система оценивания</b>: {t.test.details.gradingSystem && t.test.details.gradingSystem === 'GS_0_TO_100' ? '100 бальная' :
                                                t.test.details.gradingSystem === 'GS_1_TO_5' ? '5-ти бальная' : t.test.details.gradingSystem === 'GS_PASS_FAIL' ? 'Зачет / незачет': 'не указана' }
                                            </div>
                                            { t.test.details.gradingSystem && t.test.details.gradingSystem !== 'GS_PASS_FAIL' &&
                                            <div className="mb-2">
                                                <b>Минимальный проходной балл</b>: {t.test.details.minPoints}
                                            </div>
                                            }
                                            <div className="mb-2">
                                                <b>Результат</b>:
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center text-primary"
                             style={{minHeight: 460}}>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(Tests);
