import {
    USER_INFO_SUCCESS,
    USER_INFO_FAIL,
    SET_MESSAGE,
    GET_USER_PROFILES_SUCCESS,
    GET_USER_PROFILES_FAIL,
    SET_USER_PROFILE_FAIL,
    SET_USER_PROFILE_SUCCESS,
    GET_USER_DOCUMENTS,
    ADD_USER_DOCUMENT,
    GET_USER_PERSON,
    UPDATE_USER_DOCUMENT,
    DELETE_USER_DOCUMENT,
    UPDATE_USER_PERSON,
    GET_USER_MARKS,
    CREATE_USER_MARKS,
    DELETE_USER_MARKS,
    UPDATE_USER_MARKS, GET_APPLICANTS, GET_TEST_PARTICIPANTS
} from "./types";

import UserService from "../services/user.service";
import TokenService from "../services/token.service";
import DocumentService from "../services/document.service"

export const getUserInfo = () => (dispatch) => {
    return UserService.getUserInfo().then(
        (data) => {
            dispatch({
                type: USER_INFO_SUCCESS,
                payload: data.data.attributes
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: USER_INFO_FAIL
            });
            dispatch({
                type: SET_MESSAGE,
                payload: "Ошибка при получении данных пользователя",
            });
            TokenService.removeUser();
            return Promise.reject();
        })
};
export const getUserProfiles = () => (dispatch) => {
    return UserService.getUserProfiles().then(
        (response) => {
            if (response.data && response.data.length) {
                dispatch({
                    type: GET_USER_PROFILES_SUCCESS,
                    payload: response.data
                });
                return Promise.resolve();
            } else {
                dispatch({
                    type: GET_USER_PROFILES_FAIL
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: "Ошибка при получении профиля пользователя",
                });
                return Promise.reject();
            }
        },
        (error) => {
            dispatch({
                type: GET_USER_PROFILES_FAIL
            });
            dispatch({
                type: SET_MESSAGE,
                payload: "Ошибка при получении профиля пользователя",
            });
            return Promise.reject();
        }
    )
};
export const setUserProfile = (id) => (dispatch) => {
    return UserService.setUserProfile(id).then(
        (response) => {
            dispatch({
                type: SET_USER_PROFILE_SUCCESS,
                payload: id
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: SET_USER_PROFILE_FAIL
            });
            dispatch({
                type: SET_MESSAGE,
                payload: "Ошибка при выборе роли",
            });
            return Promise.reject();
        }
    )
};
export const getUser = (id) => (dispatch) => {
    return UserService.getUser(id).then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: GET_USER_PERSON,
                    payload: response.data.person
                });
                return Promise.resolve(response);
            } else {
                return Promise.reject(false);
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const getPersonDocuments = (id) => (dispatch) => {
    return UserService.getPersonDocuments(id).then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: GET_USER_DOCUMENTS,
                    payload: response.data
                });
                return Promise.resolve();
            } else {
                return Promise.reject()
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const getUserMarks = (id) => (dispatch) => {
    return UserService.getUserMarks(id).then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: GET_USER_MARKS,
                    payload: response.data
                });
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(false)
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const createUserMarks = (marks) => (dispatch) => {
    return UserService.createUserMarks(marks).then(
        (response) => {
            if (response.data) {
               // dispatch({
               //     type: CREATE_USER_MARKS,
               //     payload: response.data
               // });
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(false)
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const deleteUserMarks = (marks) => (dispatch) => {
    return UserService.deleteUserMarks(marks).then(
        (response) => {
            //dispatch({
            //    type: DELETE_USER_MARKS,
            //    payload: marks
            //});
            return Promise.resolve();
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const updateUserMarks = (marks) => (dispatch) => {
    return UserService.updateUserMarks(marks).then(
        (response) => {
            //dispatch({
            //    type: UPDATE_USER_MARKS,
            //    payload: marks
            //});
            return Promise.resolve();
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const saveNewUserDocument = (document) => (dispatch) => {
    return DocumentService.saveNewDocument(document).then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: ADD_USER_DOCUMENT,
                    payload: response.data
                });
                return Promise.resolve();
            } else {
                return Promise.reject()
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const updateUserDocument = (document) => (dispatch) => {
    return DocumentService.updateDocument(document).then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: UPDATE_USER_DOCUMENT,
                    payload: response.data
                });
                return Promise.resolve();
            } else {
                return Promise.reject()
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const deleteUserDocument = (id) => (dispatch) => {
    return DocumentService.deleteDocument(id).then(
        (response) => {
            dispatch({
                type: DELETE_USER_DOCUMENT,
                payload: id
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const updateUserPerson = (person, id) => (dispatch) => {
    return UserService.savePerson(person, id).then(
        (response) => {
            dispatch({
                type: UPDATE_USER_PERSON,
                payload: person
            });
            return Promise.resolve();
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const createApplicant = (applicant) => (dispatch) => {
    return UserService.createApplicant(applicant).then(
        (response) => {
            if (response.data && response.data.result && response.data.result === "OK") {
                return Promise.resolve();
            } else {
                if (response.data) {
                    return Promise.reject(response.data);
                } else {
                    return Promise.reject(false);
                }
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const getApplicants = () => (dispatch) => {
    return UserService.getApplicants().then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: GET_APPLICANTS,
                    payload: response.data
                });
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};
export const getTestParticipants = () => (dispatch) => {
    return UserService.getTestParticipants().then(
        (response) => {
            if (response.data) {
                dispatch({
                    type: GET_TEST_PARTICIPANTS,
                    payload: response.data
                });
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        },
        (error) => {
            return Promise.reject(error);
        }
    )
};



