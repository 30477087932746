import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import {Alert} from "react-bootstrap";
import {connect} from "react-redux";

class UserMenu extends Component {
    render() {
        const user = this.props.user;
        return (
            <div className="container-fluid mt-5">
                <ul className="nav nav-tabs nav-fill main-tabs">
                    <li className="nav-item">
                        <NavLink to={"/admission/intakes"}
                                 className="nav-link"
                                 activeClassName="active"
                                 isActive={(match, location) => {
                                     return location.pathname && (location.pathname === "/admission/intakes" || location.pathname === "/");
                                 }}
                        >
                            Наборы
                            <span>Выбор профессии</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={"/admission/applications"} className="nav-link"
                                 activeClassName="active">
                            Заявления
                            <span>Статусы по поданным заявлениям</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={"/admission/tests"} className="nav-link"
                                 activeClassName="active">
                            Вступительные испытания
                            <span>Расписание и результаты</span>
                        </NavLink>
                    </li>
                    {/*
                        <li className="nav-item">
                            <NavLink to={"/admission/invitations"} className="nav-link"
                                     activeClassName="active">
                                Поступление
                                <span>Конкурсные списки</span>
                            </NavLink>
                        </li>
                    */}
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}
export default connect(mapStateToProps)(UserMenu);
