import React, {Component} from "react";
import {Link} from 'react-router-dom';

import Form from "react-validation/build/form";

import CheckButton from "react-validation/build/button";

import {connect} from "react-redux";
import {login} from "../actions/auth";
import EventBus from "../common/EventBus";
import TextField from "@mui/material/TextField";
import {Alert} from "react-bootstrap";
import {CLEAR_MESSAGE} from "../actions/types";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        const {dispatch} = this.props;

        this.state = {
            username: "",
            password: "",
            loading: false,
            isLogged: props.isLoggedIn,
            selectProfile: false,
            profiles: []
        };
    }

    componentDidMount() {
        const {history} = this.props;
        if (this.state.isLogged) {
            history.push("/profile");
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    handleLogin = (e) => {
        e.preventDefault();

        this.setState({
            loading: true,
        });

        this.form.validateAll();

        const {dispatch, history} = this.props;

        if (this.checkBtn.context._errors.length === 0) {
            dispatch(login(this.state.username, this.state.password))
                .then(() => {
                    EventBus.dispatch("login");
                    history.push("/admission/intakes");
                })
                .catch(() => {
                    this.setState({
                        loading: false
                    });
                });
        } else {
            this.setState({
                loading: false,
            });
        }
    };

    closeErrorMessage = () => {
        const {dispatch} = this.props;
        dispatch({
            type: CLEAR_MESSAGE
        });
    };

    render() {
        const {isLoggedIn, message} = this.props;


        return (
            <section className="vh-100">
                <div className="h-100 w-50 d-lg-block d-none position-absolute login-banner"
                     style={{backgroundImage: 'url("' + process.env.PUBLIC_URL + '/resources/images/login-background.png")'}}>
                </div>
                <div className="container-fluid h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="d-lg-block d-none col-lg-6 col-xl-5">

                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                            <Link to={"/"}>
                                <img
                                    alt='Logo'
                                    className="mb-5 m-auto d-block"
                                    src={process.env.PUBLIC_URL + '/resources/images/app-logo.svg'}
                                    height="50"
                                />
                            </Link>
                            {!this.state.selectProfile ?
                                <Form onSubmit={this.handleLogin} className="m-auto" style={{maxWidth: 440}}
                                      ref={(c) => {
                                          this.form = c;
                                      }}>
                                    <div className="form-outline mb-4 pt-2">
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            label="Логин"
                                            value={this.state.username}
                                            onChange={this.onChangeUsername}
                                            validations={[required]}
                                        />
                                    </div>


                                    <div className="form-outline mb-3">
                                        <TextField
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            label="Пароль"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                            validations={[required]}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center">

                                        <div className="form-check mb-0">
                                            <input className="form-check-input me-2" type="checkbox" value=""
                                                   id="form2Example3"/>
                                            <label className="form-check-label" htmlFor="form2Example3">
                                                Запомнить меня
                                            </label>
                                        </div>
                                        <a href="#" className="text-body">Забыли пароль?</a>
                                    </div>

                                    <div className="text-center text-lg-start mt-4 pt-2">
                                        <button
                                            className="btn btn-primary btn-lg w-100"
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm"/>
                                            )}
                                            <span>Войти</span>
                                        </button>
                                        {message && (
                                            <Alert variant="danger" className={'mt-4'}
                                                   onClose={() => this.closeErrorMessage()} dismissible>
                                                    {message}
                                            </Alert>
                                        )}
                                        <CheckButton
                                            style={{display: "none"}}
                                            ref={(c) => {
                                                this.checkBtn = c;
                                            }}
                                        />
                                        <p className="small mt-2 pt-1 mb-0">
                                            Не зарегистрированны? <Link to="/register">Создайте аккаунт</Link>
                                        </p>
                                    </div>

                                </Form>
                                :
                                <div className="m-auto" style={{maxWidth: 440}}>
                                    <h4 className="text-center">Выберите роль</h4>
                                    {
                                        this.state.profiles.map((profile) =>
                                            <div onClick={() => {
                                                this.setUserProfile(profile.id)
                                            }} className="profile-selection">
                                                <h5>{profile.role._instanceName}</h5>
                                                {profile.college && <p>{profile.college._instanceName}</p>}
                                                {profile.child && <p>{profile.child._instanceName}</p>}
                                                {profile.district && <p>{profile.district._instanceName}</p>}
                                            </div>
                                        )
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const {profiles} = state.user;
    const {message} = state.message;
    return {
        isLoggedIn,
        message,
        profiles
    };
}

export default connect(mapStateToProps)(Login);
