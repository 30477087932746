import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Row, Col, Form} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import TextField from "@mui/material/TextField/TextField";
import CheckButton from "react-validation/build/button";

export default class Contacts extends Component {
    render() {
        return (
            <div className="container-fluid pt-5 border-top-gray">
                <div className="container">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-5">
                            <h1>Контактная информация</h1>
                            <h5 className="d-flex align-items-center mb-4">
                                <FontAwesomeIcon icon={faPhone} className="contact-icon"/> 8 812 123-45-67
                            </h5>
                            <h5 className="d-flex align-items-center">
                                <FontAwesomeIcon className="contact-icon" icon={faEnvelope}/> <a
                                href="mailto:contact:ecollege.center">contact@ecollege.center</a>
                            </h5>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={5} xl={6} className="mt-lg-5 mt-xl-0">
                            <Form className="m-auto p-4" style={{maxWidth: 440, backgroundColor:"#fff", borderRadius:15}}
                                  ref={(c) => {
                                      this.form = c;
                                  }}>
                                <div className="form-outline mb-4 pt-2">
                                    <h4 className="text-center">Задайте интересующий вас вопрос</h4>
                                </div>
                                <div className="form-outline mb-4 pt-2">
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        label="Ваше имя"
                                    />
                                </div>


                                <div className="form-outline mb-3">
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        label="Ваш email"
                                    />
                                </div>
                                <div className="form-outline mb-3">
                                    <TextField
                                        multiline
                                        minRows={3}
                                        className="form-control"
                                        name="message"
                                        label="Текст сообщения"
                                    />
                                </div>


                                <div className="text-center text-lg-start mt-4 pt-2">
                                    <button
                                        className="btn btn-primary btn-lg w-100"
                                    >
                                        <span>Отправить</span>
                                    </button>
                                </div>

                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
