import api from './api';

class UserService {
  getUserProfiles() {
    return api.get('/rest/services/profiles/getProfiles');
  }
  setUserProfile(id) {
    return api.get('/rest/services/profiles/setProfile?profileId=' + id);
  }
  getUserInfo() {
    return api.get('/rest/userInfo');
  }
  getUser(id) {
    return api.get('/rest/entities/User/' + id + '?fetchPlan=person&returnNulls=true');
  }
  getPersonDocuments(id) {
    let data = {
      "filter": {
        "conditions": [
          {
            "property": "person",
            "operator": "=",
            "value": id
          }
        ]
      }
    };
    return api.get('/rest/entities/PersonDocument/?fetchPlan=full&returnNulls=true');
  }

  savePerson(person, id) {
    return api.put('/rest/entities/Person/' + id, person)
  }
  getUserMarks(id) {
    let data = {
      person: id
    };
    return api.post('/rest/queries/SchoolCertGrade/schoolCertGradeByPerson', data)
  }
  createUserMarks(marks) {
    return api.post('/rest/entities/SchoolCertGrade?responseFetchPlan=full', marks)
  }
  deleteUserMarks(marks) {
    return api.delete('/rest/entities/SchoolCertGrade', { data: marks})
  }
  updateUserMarks(marks) {
    return api.put('/rest/entities/SchoolCertGrade', marks)
  }
  createApplicant(applicant) {
    let data = {
      applicant: applicant
    };
    return api.post('/rest/applicant/create', data)
  }
  getApplicants() {
    return api.get('/rest/entities/Applicant?fetchPlan=full')
  }
  revokeApplicant(id) {
    let data = {
      applicant: {
        id: id,
        status: "AS_REFUSED"
      }
    };
    return api.post('/rest/applicant/changeStatus', data)
  }
  getTestParticipants() {
    return api.get('/rest/entities/TestParticipant?fetchPlan=full')
  }
}

export default new UserService();
