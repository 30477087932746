import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Row, Col, Card} from "react-bootstrap"

export default class About extends Component {


    render() {
        return (
            <div className="container-fluid pt-5 border-top-gray">
                <div className="container">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7} >
                            <h1>О проекте</h1>
                            <p>Проект «Электронный колледж» разрабатывается как составная часть городской
                                образовательной среды и портала Петербургское образование.
                            </p>
                            <p>
                                Проект ориентирован на реализацию в Санкт-Петербурге ключевых стратегических задач
                                в сфере профессионального образования и науки, определенных в Указе Президента
                                Российской Федерации от 07.05.2018 № 204 «О национальных целях и стратегических
                                задачах развития Российской Федерации на период до 2024 года», Государственной
                                программе Российской Федерации «Научно‑технологическое развитие Российской
                                Федерации», утвержденной Постановлением Правительства Российской Федерации от
                                29.03.2019 № 377, Национальном проекте «Образование», Федеральном проекте
                                «Экспорт образования», Концепции внешней политики Российской Федерации и др.
                            </p>

                            <p className="">
                                Наша цель - повышение доступности среднего специального образования, повышение
                                уровня его глобальной конкурентоспособности и экспортного потенциала, развитие
                                системы непрерывного образования на базе образовательных организаций среднего
                                специального и высшего образования через создание инструментов повышения
                                открытости и доступности информации и автоматизации процесса поступления в
                                образовательные организации.
                            </p>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={5} xl={4} className=" mt-lg-5 mt-xl-0">
                            <Card bg="info" text="light">
                                <Card.Img
                                    variant="top"
                                    src={process.env.PUBLIC_URL + '/resources/images/card-about.png'} />
                                <Card.Body className="p-5">
                                    <Card.Title className="h3 mb-4">
                                        Наша миссия
                                    </Card.Title>
                                    <Card.Text >
                                        Обеспечение устойчивого развития системы среднего специального
                                        образования Санкт-Петербурга и продвижение региональных образовательных
                                        продуктов на международные рынки.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
