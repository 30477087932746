import React, {Component} from "react";
import {connect} from "react-redux";
import {Router, Switch, Route} from "react-router-dom";
import {Alert, Button, Modal} from "react-bootstrap";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Intakes from "./components/Intakes/intakes.component";
import Applications from "./components/applications.component";
import Profile from "./components/profile.component";
import Contacts from "./components/static/contacts";
import TopMenu from "./components/static/topMenu";
import notFound from "./components/static/notFound";
import UserMenu from "./components/static/userMenu";
import Confirm from "./components/confirm.component";
import Request from "./components/request.component"
import Tests from "./components/test.component"
import {logout} from "./actions/auth";
import {clearMessage} from "./actions/message";
import {history} from './helpers/history';
import EventBus from "./common/EventBus";
import {getApplicants, getUserInfo, getUserProfiles, setUserProfile} from "./actions/user";
import About from "./components/static/about";
import Admission from "./components/static/admission";
import { ToastContainer } from 'react-toastify';

class App extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.refresh = this.refresh.bind(this);

        this.state = {
            loading: true,
            showConfirmEmailHelpModal: false,
            showSelectProfileModal: false
        };

        history.listen((location) => {
            props.dispatch(clearMessage()); // clear message when changing location
        });
    }

    componentDidMount() {
        const auth = this.props.auth;
        const user = this.props.user;
        const {dispatch} = this.props;
        if (auth) {
            this.getUserInfo()
        } else {
            this.setState({
                loading: false
            })
        }

        EventBus.on("logout", () => {
            this.logOut();
        });
        EventBus.on('login', () => {
            this.getUserInfo()
        });
        EventBus.on('emailConfirmHelp', () => {
            this.setState({
                showConfirmEmailHelpModal: true
            })
        });
    }

    getUserInfo = () => {
        const {dispatch} = this.props;
        this.setState({
            loading: true
        });
        dispatch(getUserInfo())
            .then(() => {
                this.getUserProfileList();
            })
            .catch(() => {
                this.logOut();
                this.setState({
                    loading: false
                });
            });
    };

    getUserProfileList = () => {
        const {dispatch, history} = this.props;
        dispatch(getUserProfiles())
            .then(() => {
                if (this.props.user.data.emailConfirmed === null) {
                    this.logOut();
                    this.setState({
                        reLogin: true,
                        loading: false
                    });
                    return;
                }
                if (this.props.profiles.length > 1) {
                    this.setState({
                        loading: false,
                        selectProfile: true
                    });
                    return;
                }
                dispatch(setUserProfile(this.props.profiles[0].id))
                    .then(() => {
                        dispatch(getApplicants())
                            .then(() => {
                                this.setState({
                                    loading: false
                                })
                            })
                            .catch(() => {
                                this.setState({
                                    loading: false
                                })
                            })
                    })
                    .catch(() => {
                        this.setState({
                            loading: false
                        });
                    })
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
            })
    };

    setUserProfile = (id) => {
        const {dispatch} = this.props;
        dispatch(setUserProfile(id))
            .then(() => {
                this.setState({
                    loading: false,
                })
            })
            .catch((error) => {
            })
    };

    componentWillUnmount() {
        EventBus.remove("logout");
        EventBus.remove('login');
        EventBus.remove('emailConfirmHelp');
    }

    logOut() {
        this.props.dispatch(logout());
        this.setState({
            currentAuth: undefined,
        });
    }

    refresh() {
        this.setState({
            currentAuth: this.props.auth
        });
    }
    reLogin = () => {
        EventBus.dispatch("logout");
    };

    render() {
        const currentAuth = this.props.auth;
        const currentProfile = this.props.currentProfile;
        return (
            <Router history={history}>
                {!this.state.loading ?
                    <div>
                        <Route render={({location}) =>
                            location.pathname !== "/register" && location.pathname !== "/login" ? (
                                <TopMenu/>
                            ) : null
                        }
                        />
                        {
                            (currentAuth && this.props.user && this.props.user.isLoaded && this.props.user.data && !this.props.user.data.emailConfirmed) &&
                            <div className="container-fluid">
                                <Alert variant="info" className={"mb-5"}>
                                <Alert.Heading>Email не подтвержден! Доступ ограничен!</Alert.Heading>
                                При регистрации на электронную почту {this.props.user.data.email} было отправлено письмо со ссылкой. Для
                                подтверждения email откройте письмо и перейдите по этой ссылке.
                                </Alert>
                            </div>
                        }
                        <Switch>
                            <Route exact path={["/", "/admission/intakes"]}>
                                {currentAuth && this.props.currentProfile && <UserMenu/>}
                                <Intakes history={history}/>
                            </Route>
                            <Route exact path="/admission/applications">
                                {currentAuth && this.props.currentProfile && <UserMenu/>}
                                <Applications/>
                            </Route>
                            <Route exact path="/admission/tests">
                                {currentAuth && this.props.currentProfile && <UserMenu/>}
                                <Tests/>
                            </Route>
                            {/*
                                <Route exact path="/admission/invitations">
                                {currentAuth && this.props.currentProfile && <UserMenu/>}
                                <div className="container-fluid">
                                    <div className="main-content"/>
                                </div>
                            </Route>
                            */}
                            <Route exact path="/admission/request/:intakeId" component={Request}/>
                            <Route exact path="/profile" component={Profile}/>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/register" component={Register}/>
                            <Route exact path="/about" component={About}/>
                            <Route exact path="/info" component={Admission}/>
                            <Route exact path="/contacts" component={Contacts}/>
                            <Route exact path="/confirm" component={Confirm}/>
                            <Route component={notFound}/>
                        </Switch>
                        <Modal aria-labelledby="contained-modal-title-vcenter"
                               size="lg"
                               centered
                               show={this.state.showConfirmEmailHelpModal}>
                            <Modal.Header>
                                <Modal.Title>Если не пришло письмо со ссылкой</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p className="mt-3">Подача заявлений будет открыта позже</p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => {
                                    this.setState({showConfirmEmailHelpModal: false})
                                }} variant="primary">Закрыть</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal aria-labelledby="contained-modal-title-vcenter"
                               centered
                               show={this.state.showSelectProfileModal}>
                        </Modal>
                        <Modal aria-labelledby="contained-modal-title-vcenter"
                               centered
                               show={this.state.reLogin}>
                            <Modal.Body>
                                <h4>Необходимо перезайти в систему</h4>
                                <p className="mt-3">Уважаемый пользователь!</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={(e) => {
                                    this.reLogin(e)
                                }} variant="primary">Войти повторно</Button>
                            </Modal.Footer>
                        </Modal>
                        <ToastContainer/>
                    </div>
                    :
                    <div className="w-100 min-vh-100 d-flex align-items-center justify-content-center">
                        <div className="preload-animation text-center">
                            <img
                                alt='Logo'
                                className="mb-1 m-auto d-block"
                                src={process.env.PUBLIC_URL + '/favicon.svg'}
                                height="90"
                            />
                            Загрузка
                        </div>
                    </div>
                }

            </Router>
        );
    }
}

function mapStateToProps(state) {
    const {auth} = state.auth;
    const user = state.user;
    const {profiles} = state.user;
    const {currentProfile} = state.user;
    return {
        auth,
        user,
        profiles,
        currentProfile
    };
}

export default connect(mapStateToProps)(App);
