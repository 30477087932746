import {
    USER_INFO_FAIL,
    USER_INFO_SUCCESS,
    GET_USER_PROFILES_SUCCESS,
    GET_USER_PROFILES_FAIL,
    SET_USER_PROFILE_SUCCESS,
    SET_USER_PROFILE_FAIL,
    GET_USER_DOCUMENTS,
    ADD_USER_DOCUMENT,
    UPDATE_USER_DOCUMENT,
    DELETE_USER_DOCUMENT,
    GET_USER_PERSON,
    UPDATE_USER_PERSON,
    GET_USER_MARKS,
    CREATE_USER_MARKS,
    DELETE_USER_MARKS,
    UPDATE_USER_MARKS,
    GET_APPLICANTS, GET_TEST_PARTICIPANTS
} from "../actions/types";

const initialState = {
    isLoaded: false,
    data: null,
    profiles: null,
    currentProfile: null,
    personDocuments: [],
    marks: [],
    person: null,
    applicants: [],
    testParticipants: []
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_INFO_SUCCESS:
            return {
                ...state,
                data: payload,
                isLoaded: true,
            };
        case USER_INFO_FAIL:
            return {
                isLoaded: false,
                data: null,
                profiles: null,
                currentProfile: null
            };
        case GET_USER_PROFILES_SUCCESS:
            return {
                ...state,
                profiles: payload
            };
        case GET_USER_PROFILES_FAIL:
            return {
                ...state,
                profiles: null,
                currentProfile: null
            };
        case SET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                currentProfile: payload
            };
        case SET_USER_PROFILE_FAIL:
            return {
                ...state,
                currentProfile: null
            };
        case GET_USER_DOCUMENTS:
            return {
                ...state,
                personDocuments: payload
            };
        case ADD_USER_DOCUMENT:
            return {
                ...state,
                personDocuments: [
                    ...state.personDocuments,
                    payload
                ]
            };
        case UPDATE_USER_DOCUMENT:
            let documents = state.personDocuments;
            let index = documents.findIndex(d => d.id === payload.id);
            if (index !== -1) {
                documents[index] = payload;
            }
            return {
                ...state,
                personDocuments: documents
            };
        case DELETE_USER_DOCUMENT:
            let docs = state.personDocuments;
            let indexForDelete = docs.findIndex(d => d.id === payload);
            if (indexForDelete !== -1) {
                docs = docs.filter((d,i) => i !== indexForDelete)
            }
            return {
                ...state,
                personDocuments: docs
            };
        case GET_USER_PERSON:
            return {
                ...state,
                person: payload
            };
        case GET_USER_MARKS:
            return {
                ...state,
                marks: payload
            };
        case CREATE_USER_MARKS:
            return {
                ...state,
                marks: [...state.marks, ...payload]
            };
        case DELETE_USER_MARKS:
            let marksToDelete = payload;
            let currentMarks = Object.assign([], state.marks);
            currentMarks = currentMarks.filter(m => marksToDelete.indexOf(m.id) !== -1);
            return {
                ...state,
                marks: currentMarks
            };
        case UPDATE_USER_MARKS:
            let marksToUpdate = Object.assign([], state.marks);
            payload.forEach(m => {
                let index = marksToUpdate.findIndex(mtu => mtu.id === m.id);
                if (index !== -1) {
                    marksToUpdate[index] = m;
                }
            });
            return {
                ...state,
                marks: marksToUpdate
            }
        case UPDATE_USER_PERSON:
            let currentPerson = state.person;
            for (let key in payload) {
                currentPerson[key] = payload[key];
            }
            return {
                ...state,
                person: currentPerson
            };
        case GET_APPLICANTS:
            return {
                ...state,
                applicants: payload
            };
        case GET_TEST_PARTICIPANTS:
            return {
                ...state,
                testParticipants: payload
            };
        default:
            return state;
    }
}
