export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAIL = "USER_INFO_SUCCESS";
export const GET_USER_PROFILES_SUCCESS = "GET_USER_PROFILES_SUCCESS";
export const GET_USER_PROFILES_FAIL = "GET_USER_PROFILES_FAIL";
export const SET_USER_PROFILE_SUCCESS = "SET_USER_PROFILE_SUCCESS";
export const SET_USER_PROFILE_FAIL = "SET_USER_PROFILES_FAIL";
export const GET_USER_DOCUMENTS = "GET_USER_DOCUMENTS";
export const GET_USER_PERSON = "GET_USER_PERSON";
export const UPDATE_USER_PERSON = "UPDATE_USER_PERSON";
export const ADD_USER_DOCUMENT = "ADD_USER_DOCUMENT";
export const UPDATE_USER_DOCUMENT = "UPDATE_USER_DOCUMENT";
export const DELETE_USER_DOCUMENT = "DELETE_USER_DOCUMENT";

export const GET_SPECIALTY_SUCCESS = "GET_SPECIALTY_SUCCESS";
export const GET_SPECIALTY_FAIL = "GET_SPECIALTY_FAIL";

export const GET_INTAKES_SUCCESS = "GET_INTAKES_SUCCESS";
export const GET_INTAKES_FAIL = "GET_INTAKES_FAIL";
export const GET_USER_MARKS = "GET_USER_MARKS";
export const CREATE_USER_MARKS = "CREATE_USER_MARKS";
export const DELETE_USER_MARKS = "DELETE_USER_MARKS";
export const UPDATE_USER_MARKS = "UPDATE_USER_MARKS";
export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_TEST_PARTICIPANTS = "GET_TEST_PARTICIPANTS";
